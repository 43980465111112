import {minuteInMS} from "web-vcore";
import {EffectPointerPlayer} from "../../../Store/firebase/@Shared/EffectPointerPlayer.js";
import {FBAConfig_RealityCheck} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_RealityCheck.js";
import {GetYValuesForSamplesGraphYType} from "../../../UI/@Shared/NavBar/SettingsPanel/Transcribe/SamplesAggregation.js";
import {SamplesGraph_GetTicks, SamplesGraphYType} from "../../../UI/@Shared/NavBar/SettingsPanel/Transcribe/SamplesGraph.js";
import {summarySamples_bufferOfLast60s} from "../../../Utils/Bridge/Bridge_Native/PhoneSensors.js";
import {FBASession, TriggerPackage} from "../../FBASession.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {GetRandomNumber, Timer, Range} from "js-vextensions";
import {WhisperTranscriptionWatcher} from "../../../Utils/Bridge/Whisper.js";

export class RealityCheckComp extends EngineSessionComp<FBAConfig_RealityCheck> {
	constructor(session: FBASession, config: FBAConfig_RealityCheck) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	lastRCSignalTime: number|n;
	GetTriggerPackages() {
		return [
			new TriggerPackage("RealityCheck_SignalRC", this.c.signalRC_triggerSet, this, {}, async triggerInfo=>{
				// we could avoid "adding two hit events in a row", but there's some use-cases for them (eg. for marking spontaneous RCs)
				this.s.AsLocal!.AddEvent({type: "RealityCheck.ReminderHit"});
				this.lastRCSignalTime = Date.now();
				this.PlaySignalRCEffect();
			}),
			new TriggerPackage("RealityCheck_CueRC", this.c.cueRC_triggerSet, this, {}, async triggerInfo=>{
				//this.s.AsLocal!.AddEvent({type: "RealityCheck.CueRC"});
				this.PlayCueRCEffect();
			}),
		];
	}

	reminderDelayTimer = new Timer(1_000_000_000, ()=>{
		if (this.s.coreData.launchType != "day") return;
		this.TryPlayReminderEffect();
		this.StartNextReminderDelay();
	}, 1).SetContext(this.s.timerContext);
	GetNextReminderDelayInMS() {
		const reminderDelayInS = GetRandomNumber({min: this.c.reminder_minDelay, max: this.c.reminder_maxDelay});
		return reminderDelayInS * 1_000;
	}
	StartNextReminderDelay() {
		this.reminderDelayTimer.Start(this.GetNextReminderDelayInMS());
	}

	OnStart() {
		this.signalRC_effectPlayer.effectPointer = this.c.signalRC_effect;
		this.signalRC_effectPlayer.OnStart();
		this.cueRC_effectPlayer.effectPointer = this.c.cueRC_effect;
		this.cueRC_effectPlayer.OnStart();
		this.effectPlayer.effectPointer = this.c.reminder_effect;
		this.effectPlayer.OnStart();

		if (!this.IsSuspended()) {
			this.StartNextReminderDelay();
		}
		this.UpdateTranscriptionWatcherState();
	}
	OnStop() {
		this.signalRC_effectPlayer.Stop();
		this.cueRC_effectPlayer.Stop();
		this.effectPlayer.Stop();
		this.UpdateTranscriptionWatcherState();
	}

	Unsuspend(): void {
		super.Unsuspend();
		this.StartNextReminderDelay();
		this.UpdateTranscriptionWatcherState();
	}
	Suspend(): void {
		super.Suspend();
		this.reminderDelayTimer.Stop();
		this.signalRC_effectPlayer.Stop();
		this.cueRC_effectPlayer.Stop();
		this.effectPlayer.Stop();
		this.UpdateTranscriptionWatcherState();
	}

	UpdateTranscriptionWatcherState() {
		const newEnabled = this.s.IsLocal() && this.s.running && this.behaviorEnabled && !this.IsSuspended() && this.c.reminder_maxAmbientNoise < 1;
		if (newEnabled && !this.transcriptionWatcher.IsRegistered()) {
			this.transcriptionWatcher.Register();
		} else if (!newEnabled && this.transcriptionWatcher.IsRegistered()) {
			this.transcriptionWatcher.Unregister("YES, LOSING BUFFERED SAMPLES IS FINE");
		}
	}

	// players
	signalRC_effectPlayer = new EffectPointerPlayer();
	cueRC_effectPlayer = new EffectPointerPlayer();
	effectPlayer = new EffectPointerPlayer();

	// todo: once possible, mark this watcher as only caring about the mic-input being received (not whisper being-enabled/transcribing)
	transcriptionWatcher = new WhisperTranscriptionWatcher({
		keepsRecordingAlive: true,
		onChunkTranscribed: info=>{}, // do nothing; we just need this to keep the mic-recording enabled (for max-ambient-noise feature)
	});

	async PlaySignalRCEffect() {
		await this.signalRC_effectPlayer.Play();
	}
	async PlayCueRCEffect() {
		await this.cueRC_effectPlayer.Play();
	}

	async TryPlayReminderEffect() {
		const fakeUIState = {yType: SamplesGraphYType.absAvg, graphTimeToShow: 10, smoothing: 10};
		const ambientNoise = GetYValuesForSamplesGraphYType(
			fakeUIState,
			SamplesGraph_GetTicks(fakeUIState, false), SamplesGraph_GetTicks(fakeUIState, true), 
			summarySamples_bufferOfLast60s, SamplesGraphYType.absAvg,
		).LastOrX() ?? 0;

		if (ambientNoise <= this.c.reminder_maxAmbientNoise) {
			await this.PlayReminderEffect(ambientNoise);
		} else {
			this.s.AsLocal!.AddEvent({type: "RealityCheck.ReminderSkip", ambientNoise});
		}
	}
	async PlayReminderEffect(ambientNoise: number) {
		if (this.c.reminder_recordPlayEvents) {
			this.s.AsLocal!.AddEvent({type: "RealityCheck.ReminderPlay", ambientNoise});
		}

		//const lastRCEvent = this.s.AsLocal?.EventsCopy.findLast(a=>a.type == "RealityCheck.ReminderHit");
		const lastRCOrSessionStartTime = this.lastRCSignalTime ?? this.s.coreData.startTime;
		const minutesSinceRCOrStart = Math.round((Date.now() - lastRCOrSessionStartTime) / minuteInMS);
		
		await this.effectPlayer.Play(undefined, {ttsVars: {minutesSinceRC: ""+minutesSinceRCOrStart}});
	}
}