import {O} from "web-vcore";
import {ignore} from "mobx-sync";
import {computed, makeObservable} from "mobx";
import {FBAConfig} from "../../firebase/fbaConfigs/@FBAConfig";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {SessionEvent} from "../../../Engine/FBASession/SessionEvent.js";
import {LaunchType} from "../../firebase/fbaConfigs/@EngineConfig/@EC_ModeSwitcher.js";
import {SessionCoreData} from "../../firebase/sessions/@EngineSessionInfo.js";
import {AlarmSequence} from "../../firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";

export enum EngineTab {
	Host = 10,
	Client = 20,
	Log = 30,
	Timeline = 40,
	Stats = 50,
}

/*export enum EngineContext {
	WebOnly = 10,
	WebAndNative = 20,
	NativeOnly = 30,
}*/

export enum HostClientChannel {
	RealtimeDatabase = 10,
	Websockets = 20,
}
export const HostClientChannel_shortNames = {
	[HostClientChannel.RealtimeDatabase]: "RTD",
	[HostClientChannel.Websockets]: "Websockets",
};

export enum HostFindType {
	Auto = 10,
	//Select = 20,
	Manual = 30,
}

export class EngineState {
	constructor() { makeObservable(this); }
	
	@O tab = EngineTab.Host;

	// engine
	// ==========

	//localConfig: FBAConfig;
	@O selectedConfig: string;
	@O @ignore alarms_copiedSequence: AlarmSequence|n;
	@O allowClientConnections = false;
	@O showNotes = true;

	// client
	// ==========

	//engineContext = EngineContext.WebAndNative;
	@O remote_channel = HostClientChannel.RealtimeDatabase;
	@O remote_hostFindType = HostFindType.Auto;
	@O remote_hostIPAddressAndPort = "192.168.0.100:3502";
	@O @ignore remote_autoConnect = false;
	@O remoteConfig = new FBAConfig({}, true);

	/*remote_snoozeFailTrigger_volumeKeys = true;
	remote_snoozeFailTrigger_micLoudness = false;
	remote_snoozeFailTrigger_micLoudness_loudness = .1;*/

	// these are currently only used as trackers for notifying when...
	// * the session-vars are set to a whole new object/value (or null)
	@O liveFBASession_setAt: number;
	// * a log-entry is added
	@O liveFBASession_loggedAt: number;
	@computed get liveFBASession_setOrLoggedAt() { return Math.max(this.liveFBASession_setAt, this.liveFBASession_loggedAt); }
	@O liveFBASession_recoveryInfo: SessionCoreData|n;

	// log
	// ==========

	@O logTypesToShow = [LogType.Action, LogType.Event_Large];
	@O include_enabled = false;
	@O include_text = "";
	@O exclude_enabled = false;
	@O exclude_text = "";

	// stats
	// ===========

	@O evaluatorName: string;
}