import {InfoButton, Observer, TextPlus} from "web-vcore";
import {GetEntries} from "js-vextensions";
import React from "react";
import {CheckBox, Column, Row, RowLR, Select, Spinner, Text, TextInput, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {TranscribeTarget, VibrationStrength_values} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamTranscribe.js";
import {ControlInput} from "../../../@Shared/ControlInput.js";
import {ExtendFBASessionPanelSharedProps, FBASessionPanels_SharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.dreamTranscribe, (c, v)=>c.dreamTranscribe = v);

const splitAt = 200;

@Observer
export class DreamTranscribe_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Dream Transcribe (solver)" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Transcribe start:</Text>
						<ControlInput enabled={enabled} value={config.transcribeStart_triggerSet} onChange={val=>ChangeConfig(c=>c.transcribeStart_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Transcribe end:</Text>
						<ControlInput enabled={enabled} value={config.transcribeEnd_triggerSet} onChange={val=>ChangeConfig(c=>c.transcribeEnd_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info={`
							Causes transcription to be active throughout the session. (no need to trigger it)
						`.AsMultiline(0)}>Background transcribe:</TextPlus>
						<CheckBox enabled={enabled} value={config.background_enabled} onChange={val=>ChangeConfig(c=>c.background_enabled = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info={`
							* Panel: The "Transcribe" panel at bottom of Journey UI.
							* DreamSegment: The last ended/woken-from dream-segment. (if exists, else last dream-segment in general)
						`.AsMultiline(0)}>Transcribe target:</TextPlus>
						<Select enabled={enabled} displayType="button bar" options={GetEntries(TranscribeTarget, "ui")} value={config.transcribeTarget} onChange={val=>ChangeConfig(c=>c.transcribeTarget = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info={`
							On chunk transcribed, time (HH:MM) is binned based on this setting. (eg. 5 minutes = 00:00, 00:05, 00:10, etc.)
							If MM bin/multiple differs from last chunk, chunk start-time is inserted at start of chunk text, in brackets. (eg. "[00:05] ...")
							Note: 0 = always insert chunk start-time, -1 = never insert into transcribe-target, -2 = never insert anywhere
							Note: Only -2 blocks timestamp insertion into transcribe-panel as secondary target / for TTS overlaps.
						`.AsMultiline(0)}>Mark time every X minutes:</TextPlus>
						<Spinner enabled={enabled} min={-2} step={1} value={config.markTimeEveryXMinutes} onChange={val=>ChangeConfig(c=>c.markTimeEveryXMinutes = val)}/>
						<Text>minutes</Text>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info={`
							Adds brackets around word-spans spoken within X seconds of TTS utterances, to help user distinguish words that may have been spoken by TTS but misinterpreted by the transcriber.
							Note: The time-period of transcribed words is approximate. (*usually* erring toward thinking an utterance took longer than it actually did)
							Note: The TTS system already tries to filter out its *exact words* from transcription (see settings), but this option can be helpful for words the exact-matching missed. (eg. misheard TTS words)
							Note: This value can be set negative to only affect words "toward the center" of the approximate TTS utterance time-periods. (-99 would effectively disable the bracketing entirely)
						`.AsMultiline(0)}>Bracket words near TTS:</TextPlus>
						<Spinner enabled={enabled} step={0.1} value={config.bracketWordsNearTTS} onChange={val=>ChangeConfig(c=>c.bracketWordsNearTTS = val)}/>
						<Text>s</Text>
					</RowLR>
					<RowLR mt={5} ml={15} splitAt={splitAt - 15}>
						<Text>Exclude from:</Text>
						<CheckBox enabled={enabled} text="Speak-count/vibrate" value={config.bracketWordsNearTTS_noSpeakCountOrVibrate} onChange={val=>ChangeConfig(c=>c.bracketWordsNearTTS_noSpeakCountOrVibrate = val)}/>
						<InfoButton ml={5} text="If this is checked, the near-tts-bracketed words don't count toward the criteria for speaking a transcribed-word count, or vibrating the phone."/>
						<CheckBox ml={10} enabled={enabled} text="Dream segment" value={config.bracketWordsNearTTS_noDJ} onChange={val=>ChangeConfig(c=>c.bracketWordsNearTTS_noDJ = val)}/>
						<InfoButton ml={5} text={`If this is checked, the near-tts-bracketed words are never inserted into DJ dream-segments. (falls back to placement in transcribe-panel, with timestamp)`}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>On transcribe, speak word count:</Text>
						<CheckBox enabled={enabled} value={config.onTranscribe_speakWordCount} onChange={val=>ChangeConfig(c=>c.onTranscribe_speakWordCount = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info="(0 means no vibration)">On transcribe, vibrate for:</TextPlus>
						<Spinner enabled={enabled} min={0} step={.1} value={config.onTranscribe_vibrateDuration} onChange={val=>ChangeConfig(c=>c.onTranscribe_vibrateDuration = val)}/>
						<Text>s</Text>
						<Text ml={5}>Strength:</Text>
						<Select ml={5} enabled={enabled} options={VibrationStrength_values} value={config.onTranscribe_vibrateStrength} onChange={val=>ChangeConfig(c=>c.onTranscribe_vibrateStrength = val)}/>
					</RowLR>

					<Row mt={5}>
						<CheckBox enabled={enabled} text="Use transcription as solver" value={config.solver_enabled} onChange={val=>ChangeConfig(c=>c.solver_enabled = val)}/>
					</Row>
					{config.solver_enabled &&
					<Column mt={5} ml={10}>
						<RowLR splitAt={splitAt - 10}>
							<Text>Min words:</Text>
							<Spinner enabled={enabled} min={0} value={config.solver_minWords} onChange={val=>ChangeConfig(c=>c.solver_minWords = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt - 10}>
							<TextPlus info={`
								Ignores word-spans spoken within X seconds of TTS utterances, to avoid TTS words being counted by this solver. (also excludes those time-ranges from the "min time" requirement)
								Note: The time-period of transcribed words is approximate. (*usually* erring toward thinking an utterance took longer than it actually did)
								Note: The TTS system already tries to filter out its *exact words* from transcription (see settings), but this option can be helpful for words the exact-matching missed. (eg. misheard TTS words)
								Note: This value can be set negative to only exclude words "toward the center" of the approximate TTS utterance time-periods. (-99 would effectively disable the filtering entirely)
							`.AsMultiline(0)}>Ignore words near TTS:</TextPlus>
							<Spinner enabled={enabled} step={0.1} value={config.solver_ignoreWordsNearTTS} onChange={val=>ChangeConfig(c=>c.solver_ignoreWordsNearTTS = val)}/>
							<Text>s</Text>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt - 10}>
							<TextPlus info={`
								If specified, the words spoken must include the following word sequence. (words in between are fine though)
								Note: Only alphanumerics + apostrophes are considered, and "(" + ")" + "|" (which can be used as separators for alternatives).
								Example: "I will dream (quiz|quits) at least once" (this can fix handling of common transcription mistakes)
								Note2: If text starts and ends with "/", it's treated as a case-insensitive regex pattern (match in buffered transcription-text = success).
								Example2: "/i.+will.+dream.+(quiz|quits).+at.+least.+once/"
							`.AsMultiline(0)}>Word sequence:</TextPlus>
							<TextInput enabled={enabled} style={{flex: 1}}
								value={config.solver_wordSequence} onChange={val=>ChangeConfig(c=>c.solver_wordSequence = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt - 10}>
							<Text>Min time:</Text>
							<TimeSpanInput enabled={enabled} min={0} value={config.solver_minTime} onChange={val=>ChangeConfig(c=>c.solver_minTime = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt - 10}>
							<Text>Max time per word:</Text>
							<Spinner enabled={enabled} min={0} step={0.1} value={config.solver_maxTimeValuePerWord} onChange={val=>ChangeConfig(c=>c.solver_maxTimeValuePerWord = val)}/>
							<Text>s</Text>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt - 10}>
							<TextPlus info={`
								When enabled, the solver is able to mark progress even before the completion criteria is met.
								Example: If 3 words transcribed (with 5 required), solver still marks progress. (thus, eg. interrupting the alarms)
							`.AsMultiline(0)}>Mark progress before completion:</TextPlus>
							<CheckBox enabled={enabled} value={config.solver_markProgressBeforeCompletion} onChange={val=>ChangeConfig(c=>c.solver_markProgressBeforeCompletion = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt - 10}>
							<Text mr={5}>Max time for progress:</Text>
							<Spinner enabled={enabled} value={config.solver_maxTimeForProgress} onChange={val=>ChangeConfig(c=>c.solver_maxTimeForProgress = val)}/>
							<Text>s</Text>
						</RowLR>
					</Column>}
				</>}
			</Column>
		);
	}
}