import {Assert, GetEntries, Range} from "js-vextensions";
import {StoreAccessor} from "mobx-firelink";
import {AssertUnreachable, minuteInMS} from "web-vcore";
import {eventTypes_dreamOrSoundQuiz_promptEnder, eventTypes_dreamQuiz} from "../../../../Engine/FBASession/SessionEvent.js";
import {SessionPeriod, SleepCycle} from "../../../../Store/firebase/@Shared/SessionPeriod.js";
import {GetSessionPeriods, GetSessionPeriods_Me} from "../../../../Store/firebase/@Shared/SessionPeriodUtils.js";
import {IsMetaTerm} from "../../../../Store/firebase/entities.js";
import {EstimateDreamEndTime, EstimateDreamStartTime, GetJournalEntries} from "../../../../Store/firebase/journalEntries.js";
import {GetSegmentAllText, GetTermsInDreamSegment, JournalEntry, JournalSegment} from "../../../../Store/firebase/journalEntries/@JournalEntry.js";
import {EngineSessionInfo, GetSettingValueFromEngineSessionInfo} from "../../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {JourneyStatsState, SmoothingType, StatsGrouping, StatsXType, StatsYType} from "../../../../Store/main/tools/journey.js";
import {GetDayOffset, JStatsUI_GetTicks} from "../JourneyStatsUI.js";
import {ALL_GROUP_NAME, MetricCollector} from "./MetricCollector.js";
import {store} from "../../../../Store/index.js";
import {GetSessions} from "../../../../Store/firebase/sessions.js";
import {MeID} from "../../../../Store/firebase/users.js";
import {StatViewFull} from "../../../../Store/firebase/statViews/@StatView.js";

export type AggregationMeta = ReturnType<typeof CreateAggregationMeta>;
// this accessor func creates a caching-point, so the returned object can be safely passed as argument without breaking downstream cache
export const CreateAggregationMeta = StoreAccessor(s=>(
	// from ui-state (as main case)
	xType: StatsXType,
	yType: StatsYType,
	combined_yTypes: StatsYType[],
	grouping: StatsGrouping,
	dreamMatcher_text: string,
	dateRange_enabled: boolean,
	dateRange_min: number|n,
	dateRange_max: number|n,
	// other args
	xTicks: number[],
)=>{
	Assert(GetEntries(StatsGrouping).map(a=>a.value).includes(grouping), `Invalid grouping! Got:${grouping}`);
	return {
		xType, yType, combined_yTypes, grouping, dreamMatcher_text,
		dateRange_enabled, dateRange_min, dateRange_max,
		xTicks,
	};
});

export class AggregationData {
	// groups
	alarmSequences = [] as string[];
	alarmDelays = [] as number[];
	globalVolumeMods = [] as number[];
	globalAlarmRestartIntervalMods = [] as number[];

	// samples
	sessionsForEachXValue = new Map<number, EngineSessionInfo[]>();
	//dreamsForEachXValue = new Map<number, JournalEntry[]>();
	segmentsForEachXValue = new Map<number, JournalSegment[]>();
	//compositeSessionForEachXValue = new Map<number, {proHits: number, conHits: number}>();
	segmentExistenceSamples = new MetricCollector<number>();
	segmentIsLucidSamples = new MetricCollector<number>();
	segmentIsMatchSamples = new MetricCollector<boolean>();
	segmentTermCountSamples = new MetricCollector<number>();
	responseTimeSamples = new MetricCollector<number>();
	quizPromptHasHitSamples = new MetricCollector<boolean>();
	quizTryIsHitSamples = new MetricCollector<boolean>();
	quizFirstTryIsHitSamples = new MetricCollector<boolean>();
	quizTimeTillSuccessSamples = new MetricCollector<number>();
	linkVoicingExistenceSamples = new MetricCollector<boolean>();
	linkVisualizationExistenceSamples = new MetricCollector<boolean>();
	realityCheckExistenceSamples = new MetricCollector<boolean>();

	// for debugging
	groupCycles = new Map<string, SleepCycle[]>();
	groupDreamCycles = new Map<string, SleepCycle[]>();
}

/*export function JStatsUI_GetAggregationData_Standard() {
	const uiState = store.main.tools.journey.stats;
	const xTicks = JStatsUI_GetTicks(uiState);
	const sessions = GetSessions(MeID());
	const dreams = GetJournalEntries(MeID());
	const meta = CreateAggregationMeta(
		uiState.xType, uiState.yType, uiState.combined_yTypes, uiState.grouping,
		uiState.dateRange_enabled, uiState.dateRange_min, uiState.dateRange_max,
		xTicks,
	);
	const aggregationData = JStatsUI_GetAggregationData(xTicks, sessions, dreams, meta);
	return aggregationData;
}*/

export function GetMainAlarmSequenceFromSession(session: EngineSessionInfo) {
	return session.config.alarms?.phaseAlarm_sequences?.[0]?.name ?? "[none]";
}

export function JStatsUI_GetAggregationData(
	sessions_raw: EngineSessionInfo[], dreams_raw: JournalEntry[],
	// from ui-state (as main case)
	meta: AggregationMeta,
): AggregationData {

	const sessions = sessions_raw.filter(session=>{
		if (meta.dateRange_enabled && meta.dateRange_min && session.startTime < meta.dateRange_min) return false;
		if (meta.dateRange_enabled && meta.dateRange_max && session.endTime > meta.dateRange_max) return false;
		return true;
	});
	const dreams = dreams_raw.filter(dream=>{
		const startTime = EstimateDreamStartTime(dream);
		const endTime = EstimateDreamEndTime(dream);
		if (meta.dateRange_enabled && meta.dateRange_min && startTime < meta.dateRange_min) return false;
		if (meta.dateRange_enabled && meta.dateRange_max && endTime && endTime > meta.dateRange_max) return false;
		return true;
	});

	const sessionPeriods = GetSessionPeriods(dreams, sessions);

	/*const sessionPeriodsBasic = GetSessionPeriods_Me();
	Assert(sessionPeriodsBasic.length == sessionPeriods.length, "Test1");
	console.log("Has target period1:", sessionPeriods.Any(a=>a.startTime == 1732176001000));
	console.log("Has target period2:", sessionPeriodsBasic.Any(a=>a.startTime == 1732176001000));*/

	const allSessions = sessionPeriods.SelectMany(a=>a.sessions);
	/*const allCycles = sessionPeriods.SelectMany(a=>a.sleepCycles);
	const getPeriodForSession = (session: EngineSessionInfo)=>sessionPeriods.find(a=>a.sessions.includes(session))!;
	const getPeriodForDream = (entry: JournalEntry)=>sessionPeriods.find(a=>a.journalEntries.includes(entry))!;
	const getPeriodForSegment = (segment: JournalSegment)=>sessionPeriods.find(a=>a.journalSegments.includes(segment))!;
	const getPeriodForCycle = (cycle: SleepCycle)=>sessionPeriods.find(a=>a.sleepCycles.includes(cycle))!;*/

	const data = new AggregationData();

	// groups
	// ===========

	data.alarmSequences = allSessions.SelectMany(a=>{
		//return a.config.alarms?.phaseAlarm_sequences?.map(a=>a.name) ?? [];
		// for now at least, only consider each session's main alarm-sequence (avoid legend clutter from defined but never-used sequences)
		return a.alarms_mainSequence ? [a.alarms_mainSequence] : [];
	}).Distinct().OrderBy(a=>a);

	data.alarmDelays = allSessions.SelectMany(a=>{
		const jConf = a.config["journey"] ?? {};
		const aConf = a.config.alarms ?? {};
		const validNums = (...possibleNums: any[])=>possibleNums.filter(a=>a != null && typeof a == "number" && !isNaN(a));

		const delaysInMS = [] as number[];
		if (validNums(jConf.resleep_waitDuration)) {
			delaysInMS.push(jConf.resleep_waitDuration);
		}
		if (validNums(jConf.resleep_waitDuration_min, jConf.resleep_waitDuration_max, jConf.resleep_waitDuration_step)){
			delaysInMS.push(...Range(jConf.resleep_waitDuration_min, jConf.resleep_waitDuration_max, jConf.resleep_waitDuration_step));
		}
		if (jConf.alarmDelay_pool != null && validNums(...jConf.alarmDelay_pool)) {
			delaysInMS.push(...jConf.alarmDelay_pool.map(a=>a * minuteInMS));
		}
		if (aConf.alarmDelay_pool != null && validNums(...aConf.alarmDelay_pool)) {
			delaysInMS.push(...aConf.alarmDelay_pool.map(a=>a * minuteInMS));
		}
		return delaysInMS.map(a=>(a / minuteInMS).RoundTo(1)).Distinct();
	}).Distinct().OrderBy(a=>a);

	data.globalVolumeMods = allSessions.map(a=>{
		return GetSettingValueFromEngineSessionInfo(a, b=>b.general.globalVolumeMultiplier, 1);
	}).Distinct().OrderBy(a=>a);

	data.globalAlarmRestartIntervalMods = allSessions.map(a=>{
		return GetSettingValueFromEngineSessionInfo(a, b=>b.general.globalAlarmRestartIntervalMultiplier, 1);
	}).Distinct().OrderBy(a=>a);

	// samples
	// ==========

	let periodGroups = new Map<SessionPeriod, string>();
	let groupPeriods = new Map<string, SessionPeriod[]>();
	for (const period of sessionPeriods) {
		const group = (()=>{
			if (meta.grouping == StatsGrouping.none) {
				return ALL_GROUP_NAME;
			}
			if (meta.grouping == StatsGrouping.alarmSequence) {
				return period.sessions.map(a=>a.alarms_mainSequence).find(a=>a) ?? "[none]";
			}
			if (meta.grouping == StatsGrouping.alarmDelay) {
				// if a session-period...
				// * has no actual dream journal-segments
				// * OR, it has no actual sleep-cycles (with an alarm-delay AND dream journal-segments associated with it)
				// ...then always consider its alarm-delay group as "NaN".
				// (we don't want these session-periods messing up the per-group stats)
				/*const relevantDreamSegments = period.journalSegments.filter(a=>a.wakeTime == null);
				const relevantSleepCycles = period.sleepCycles.filter(a=>{
					if (a.cycleNumber == 0 || a.alarmDelay == null || isNaN(a.alarmDelay)) return false;
					if (a.associatedDreamSegments.filter(b=>b.wakeTime == null).length == 0) return false;
					return true;
				});
				if (relevantDreamSegments.length == 0 || relevantSleepCycles.length == 0) return "NaN";*/

				return period.sleepCycles.filter(a=>a.cycleNumber != 0).map(a=>a.alarmDelay).find(a=>a)?.toString() ?? "NaN";
			}
			if (meta.grouping == StatsGrouping.volumeMod) {
				return period.sessions.map(a=>GetSettingValueFromEngineSessionInfo<number>(a, b=>b.general.globalVolumeMultiplier, 1)).find(a=>a)?.toString() ?? "NaN";
			}
			if (meta.grouping == StatsGrouping.alarmRestartIntervalMod) {
				return period.sessions.map(a=>GetSettingValueFromEngineSessionInfo<number>(a, b=>b.general.globalAlarmRestartIntervalMultiplier, 1)).find(a=>a)?.toString() ?? "NaN";
			}
			AssertUnreachable(meta.grouping);
		})();
		periodGroups.set(period, group);
		groupPeriods.set(group, (groupPeriods.get(group) ?? []).concat(period));
	}
	data.groupCycles = [...groupPeriods].ToMap(a=>a[0], a=>a[1].SelectMany(b=>b.sleepCycles));
	const isDreamCycle = (cycle: SleepCycle)=>cycle.associatedDreamSegments.filter(a=>a.wakeTime == null).length > 0;
	data.groupDreamCycles = [...groupPeriods].ToMap(a=>a[0], a=>a[1].SelectMany(b=>b.sleepCycles.filter(isDreamCycle)));

	let dreamCyclesProcessedPerGroup = new Map<string, number>();
	for (const period of sessionPeriods) {
		const group = periodGroups.get(period)!;
		for (const cycle of period.sleepCycles) {
			const xTick = (()=>{
				if (meta.xType == StatsXType.showAll) return 0;
				if (meta.xType == StatsXType.dayOffset) return GetDayOffset(period.startTime);
				if (meta.xType == StatsXType.cycleInGroup) {
					if (isDreamCycle(cycle)) {
						const dreamCycleNumberInGroup = (dreamCyclesProcessedPerGroup.get(group) ?? 0) + 1;
						dreamCyclesProcessedPerGroup.set(group, dreamCycleNumberInGroup);
						return -dreamCycleNumberInGroup.Distance(data.groupDreamCycles.get(group)!.length);
					}
					return null;
				}
				if (meta.xType == StatsXType.cycleInNight) return cycle.cycleNumber;
				AssertUnreachable(meta.xType);
			})();
			// if xTick is null, it means this cycle is not relevant for the current xType, so skip
			if (xTick == null) continue;
			/*if (period.journalSegments.Any(a=>a.lucid) && group != "NaN" && meta.xType == StatsXType.cycleInGroup) {
				debugger;
			}*/
			IntegrateSleepCycleIntoAggregationData(data, meta, cycle, period, xTick, group);
		}
	}

	return data;
}

function IntegrateSleepCycleIntoAggregationData(
	data: AggregationData, meta: AggregationMeta, cycle: SleepCycle, period: SessionPeriod,
	xTick: number, group: string,
) {
	for (const session of cycle.associatedSessions) {
		data.sessionsForEachXValue.set(xTick, (data.sessionsForEachXValue.get(xTick) ?? []).concat(session));
	}

	// cycle with cycle-number 0 are "fake" cycles, used to represent eg. daytime session-periods
	if (cycle.cycleNumber != 0) {
		// todo: maybe change this to consider multiple sessions that cover the same sleep (ie. journal-entry) period, as part of one "composite session"
		data.responseTimeSamples.AddSample(xTick, cycle.responseTime, cycle.alarmDelay.toString());
	}

	for (const [index, eventGroup] of cycle.associatedEventGroups.entries()) {
		const priorEventGroups = cycle.associatedEventGroups.slice(0, index);
		const priorEventGroupEvents = priorEventGroups.SelectMany(a=>a.events);

		for (const [event_index, event] of eventGroup.events.entries()) {
			const priorEvents = priorEventGroupEvents.concat(eventGroup.events.slice(0, event_index));
			
			// dream-quiz events
			const dreamQuiz_lastHitOrMissOrGiveUp = priorEvents.findLast(a=>eventTypes_dreamQuiz.includes(a.type));
			if (event.type == "DreamQuiz.TargetHit") {
				data.quizPromptHasHitSamples.AddSample(xTick, true, group);
				data.quizTryIsHitSamples.AddSample(xTick, true, group);
				const isFirstTry = dreamQuiz_lastHitOrMissOrGiveUp?.type != "DreamQuiz.TargetMiss";
				if (isFirstTry) {
					data.quizFirstTryIsHitSamples.AddSample(xTick, true, group);
				}

				const lastIndexBeforeQuizEventsBlock = priorEvents.findLastIndex(a=>!eventTypes_dreamQuiz.includes(a.type));
				const priorEventsInQuizEventsBlock = lastIndexBeforeQuizEventsBlock == -1 ? [] :
					priorEvents.slice(lastIndexBeforeQuizEventsBlock + 1);
				const quiz_lastHitOrGiveUpEarlierInBlock = priorEventsInQuizEventsBlock.findLast(a=>eventTypes_dreamOrSoundQuiz_promptEnder.includes(a.type));
				if (quiz_lastHitOrGiveUpEarlierInBlock) {
					const timeTillSuccess = event.date - quiz_lastHitOrGiveUpEarlierInBlock.date;
					// hard-coded: if a quiz-prompt takes over 60s to solve, assume it was interrupted and discard it
					if (timeTillSuccess <= 60000) {
						data.quizTimeTillSuccessSamples.AddSample(xTick, timeTillSuccess, group);
					}
				}
			} else if (event.type == "DreamQuiz.TargetMiss") {
				data.quizTryIsHitSamples.AddSample(xTick, false, group);
				const isFirstTry = dreamQuiz_lastHitOrMissOrGiveUp?.type != "DreamQuiz.TargetMiss";
				if (isFirstTry) {
					data.quizFirstTryIsHitSamples.AddSample(xTick, false, group);
				}
			} else if (event.type == "DreamQuiz.TargetGiveUp") {
				data.quizPromptHasHitSamples.AddSample(xTick, false, group);
			} else if (event.type == "ConceptLink.TargetVoiced") {
				data.linkVoicingExistenceSamples.AddSample(xTick, true, group);
			} else if (event.type == "ConceptLink.TargetVisualized") {
				data.linkVisualizationExistenceSamples.AddSample(xTick, true, group);
			} else if (event.type == "RealityCheck.ReminderHit") {
				data.realityCheckExistenceSamples.AddSample(xTick, true, group);
			}
		}
	}

	//if (cycle.cycleNumber != 0) // commented; check not really needed, since "fake" sleep-cycles don't have journal-segments anyway
	for (const segment of cycle.associatedDreamSegments) {
		IntegrateJournalSegmentIntoAggregationData(data, meta, segment, period, xTick, group);
	}
}

function IntegrateJournalSegmentIntoAggregationData(
	data: AggregationData, meta: AggregationMeta, segment: JournalSegment, period: SessionPeriod,
	xTick: number, group: string,
) {
	if (segment.wakeTime != null) return; // only dream/non-wake segments are relevant atm
	//const time = EstimateTimeOfSegment(dream, segment);

	// optimization (and range-applying if using smoothing): skip segments that are too far outside the x-values range
	// commented; this cutoff would change the data lines (when using smoothing) depending on how large of a view window, which is confusing
	/*const xValues_min = meta.xTicks.First();
	const xValues_max = meta.xTicks.Last();
	if (xTick < xValues_min || xTick > xValues_max) return;*/

	data.segmentsForEachXValue.set(xTick, (data.segmentsForEachXValue.get(xTick) ?? []).concat(segment));

	// todo: probably remove segmentsForEachXValue, and just use segmentExistenceSamples directly
	data.segmentExistenceSamples.AddSample(xTick, 1, group);

	const lucidityValue =
		segment.lucid ? 1 :
		segment.semiLucid ? .25 :
		0;
	data.segmentIsLucidSamples.AddSample(xTick, lucidityValue, group);

	const textHasMatch = (text: string, matcherText: string)=>{
		if (matcherText.startsWith("/") && matcherText.endsWith("/")) {
			const regex = new RegExp(matcherText.slice(1, -1), "i");
			return regex.test(text);
		}
		return text.toLowerCase().includes(matcherText.toLowerCase());
	};
	const isMatch = textHasMatch(GetSegmentAllText(segment), meta.dreamMatcher_text);
	data.segmentIsMatchSamples.AddSample(xTick, isMatch, group);

	const yTypes = meta.yType == StatsYType.combined ? meta.combined_yTypes : [meta.yType];
	const segmentTerms = GetTermsInDreamSegment(segment, yTypes.ContainsAny(StatsYType.termsInShortText, StatsYType.termsInShortText_sum) ? "shortText" : "longText", false);
	const validSegmentTerms = segmentTerms.filter(a=>!IsMetaTerm(a));
	data.segmentTermCountSamples.AddSample(xTick, validSegmentTerms.length, group);
}

export function GetYValuesForYType(view: StatViewFull, xTicks: number[], xTicks_extendedForSmoothing: number[], aggregationData: AggregationData, yType: StatsYType, group: string) {
	const {
		segmentExistenceSamples, segmentIsLucidSamples, segmentIsMatchSamples, segmentTermCountSamples, responseTimeSamples,
		quizPromptHasHitSamples, quizTryIsHitSamples, quizFirstTryIsHitSamples, quizTimeTillSuccessSamples,
		linkVoicingExistenceSamples, linkVisualizationExistenceSamples, realityCheckExistenceSamples,
	} = aggregationData;

	const getAvgOfMidXPercent = (
		sampleCollector: MetricCollector<number>, xValue: number, group: string,
		resultIfNoSamples: number|null, nonNullResultTransform = (result: number)=>result,
	)=>{
		resultIfNoSamples = null; // maybe temp; I actually like null to always be used for "no samples"
		
		const samples = sampleCollector.GetSampleValues(xValue, group);
		if (samples.length == 0) return resultIfNoSamples;
		const samples_sorted = samples.OrderBy(a=>a);
		
		// special case: if middle-keep-% is 0, interpret that as meaning "keep just the median value"
		if (view.middleKeepPercent == 0) {
			return nonNullResultTransform(samples_sorted.Median());
		}
		const percentToTrimAtEachExtreme = (1 - view.middleKeepPercent) / 2;
		const samples_sorted_toKeep_startIndex = Math.floor(samples_sorted.length * percentToTrimAtEachExtreme);
		const samples_sorted_toKeep = samples.slice(samples_sorted_toKeep_startIndex, samples_sorted.length - samples_sorted_toKeep_startIndex);
		return nonNullResultTransform(samples_sorted_toKeep.Average());
	};
	const getSum = (sampleCollector: MetricCollector<number>, xValue: number, group: string, resultIfNoSamples: number|null)=>{
		resultIfNoSamples = null; // maybe temp; I actually like null to always be used for "no samples"

		const samples = sampleCollector.GetSampleValues(xValue, group);
		if (samples.length == 0) return resultIfNoSamples;
		return samples.Sum();
	}
	const getSum_boolean = (sampleCollector: MetricCollector<boolean>, xValue: number, group: string, resultIfNoSamples: number|null)=>{
		resultIfNoSamples = null; // maybe temp; I actually like null to always be used for "no samples"

		const samples = sampleCollector.GetSampleValues(xValue, group);
		if (samples.length == 0) return resultIfNoSamples;
		return samples.filter(a=>a).length;
	}
	const getPercentTrue = (sampleCollector: MetricCollector<boolean>, xValue: number, group: string, resultIfNoSamples: number|null)=>{
		resultIfNoSamples = null; // maybe temp; I actually like null to always be used for "no samples"

		const samples = sampleCollector.GetSampleValues(xValue, group);
		if (samples.length == 0) return resultIfNoSamples;
		const hitCount = samples.filter(a=>a).length;
		return ((hitCount / samples.length) * 100).RoundTo(1);
	}

	let yValues: Array<number|n>;
	const xTicks_ext = xTicks_extendedForSmoothing; // just a shorter alias
	if (yType == StatsYType.dreamSegments_sum) {
		yValues = xTicks_ext.map(x=>getSum(segmentExistenceSamples, x, group, 0));
	} else if (yType == StatsYType.lucids_sum) {
		yValues = xTicks_ext.map(x=>getSum(segmentIsLucidSamples, x, group, 0));
	} else if  (yType == StatsYType.dreamsMatching_sum) {
		yValues = xTicks_ext.map(x=>getSum_boolean(segmentIsMatchSamples, x, group, 0));
	} else if (yType == StatsYType.termsInShortText || yType == StatsYType.termsInLongText) {
		yValues = xTicks_ext.map(x=>getAvgOfMidXPercent(segmentTermCountSamples, x, group, 0, a=>a.RoundTo(1)));
	} else if (yType == StatsYType.termsInShortText_sum || yType == StatsYType.termsInLongText_sum) {
		yValues = xTicks_ext.map(x=>getSum(segmentTermCountSamples, x, group, 0));
	} else if (yType == StatsYType.responseTime) {
		yValues = xTicks_ext.map(x=>getAvgOfMidXPercent(responseTimeSamples, x, group, null, a=>(a / 1000).RoundTo(1)));
	} else if (yType == StatsYType.linkVoicings_sum) {
		yValues = xTicks_ext.map(x=>linkVoicingExistenceSamples.GetSampleValues(x, group).length);
	} else if (yType == StatsYType.linkVisualizations_sum) {
		yValues = xTicks_ext.map(x=>linkVisualizationExistenceSamples.GetSampleValues(x, group).length);
	} else if (yType == StatsYType.realityChecks_sum) {
		yValues = xTicks_ext.map(x=>realityCheckExistenceSamples.GetSampleValues(x, group).length);
	} else if (yType == StatsYType.quizPrompts_sum) {
		yValues = xTicks_ext.map(x=>quizPromptHasHitSamples.GetSampleValues(x, group).length);
	} /*else if (yType == StatsYType.quizTryHitPercent) {
		yValues = xTicks_ext.map(xValue=>{
			const trySamples = quizTryIsHitSamples.GetSampleValues(xValue, group);
			if (trySamples.length == 0) return 0;
			const hitCount = trySamples.filter(a=>a).length;
			return ((hitCount / trySamples.length) * 100).RoundTo(1);
		});
	}*/ else if (yType == StatsYType.quizFirstTryHitPercent) {
		yValues = xTicks_ext.map(x=>getPercentTrue(quizFirstTryIsHitSamples, x, group, null));
	} else if (yType == StatsYType.quizTimeTillSuccess) {
		yValues = xTicks_ext.map(x=>getAvgOfMidXPercent(quizTimeTillSuccessSamples, x, group, null, a=>(a / 1000).RoundTo(.1)));
	} else {
		Assert(false, "Invalid yType.");
	}
	//console.log("YValues:", yValues, "aggData:", aggregationData);

	const valMultiplier = GetValMultiplierForGroupMetricNormalization(view, group, yType);
	if (valMultiplier != 1) {
		yValues = yValues.map(val=>val == null ? val : (val * valMultiplier).RoundTo(1));
	}

	if (StatViewFull.WillApplySmoothing(view)) {
		yValues = SmoothLine(yValues, view.smoothing, view.smoothingType) as number[];
		// now that smoothing is done, trim the yValues to the correct/non-extended length
		yValues = yValues.slice(-xTicks.length);
	} else {
		Assert(xTicks_extendedForSmoothing.length == xTicks.length, "If not smoothing, xTicks_extendedForSmoothing should be same as xTicks.");
	}

	return yValues;
}

export function GetValMultiplierForGroupMetricNormalization(view: StatViewFull, group: string, metric: StatsYType | "lucidityRate") {
	if (view.normalizeGroupMetrics && view.grouping == StatsGrouping.alarmDelay) {
		const alarmDelayStandard = view.normalizeGroupMetrics_alarmDelay;
		const alarmDelayForCurrentGroup = Number(group);
		const metricMakesSenseToNormalize = [StatsYType.termsInShortText, StatsYType.termsInLongText, "lucidityRate"].includes(metric);
		if (metricMakesSenseToNormalize) {
			// if our wake-delay is 40mins, and the "standard" is 80mins, multiply our values by 2
			// (to normalize them, eg. so user can extrapolate results to "total amount expected per 8-hour night")
			const valMultiplier = alarmDelayStandard / alarmDelayForCurrentGroup;
			return valMultiplier;
		}
	}
	return 1;
}

export function SmoothLine(values: (number|n)[], smoothing: number, smoothType = SmoothingType.centered) {
	return values.map((value, index)=>{
		//if (values[i] == null) continue;
		if (value == null) return null;
		if (isNaN(value)) return null; // correct?
		const valuesToAverage = smoothType == SmoothingType.centered
			? values.slice((index - ((smoothing / 2) + (smoothing % 2))).KeepAtLeast(0), index + (smoothing / 2) + 1)
			: values.slice((index - smoothing).KeepAtLeast(0), index + 1);
		return (valuesToAverage.filter(a=>a != null && !isNaN(a) && a != Infinity && a != -Infinity) as number[]).Average();
	});
}