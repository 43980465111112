import {AddSchema, AssertValidate, Command, GetSchemaJSON, NewSchema} from "mobx-firelink";
import {GetStatView} from "../../Store/firebase/statViews";
import {StatViewFull, StatView} from "../../Store/firebase/statViews/@StatView";

type MainType = StatView;
const MTName = "StatView";

AddSchema(`Update${MTName}_payload`, [MTName], ()=>({
	properties: {
		id: {type: "string"},
		updates: NewSchema({
			properties: GetSchemaJSON(MTName).properties!.ExcludeKeys("creator", "createdAt"),
		}),
		// alternative, used for edit-json dialog (lets you delete properties, including old ones no longer in schema)
		newData: {type: "object"}, // todo: add schema restrictions for this
	},
	//required: ["id", "updates"],
	required: ["id"],
}));

export class UpdateStatView extends Command<{id: string, updates?: Partial<MainType>, newData?: MainType}> {
	oldData: MainType;
	newData: MainType;
	Validate() {
		AssertValidate(`Update${MTName}_payload`, this.payload, `Payload invalid`);

		const {id, updates, newData} = this.payload;
		this.oldData = GetStatView.NN(id);

		if (newData != null) {
			this.newData = newData;
		} else {
			this.newData = {...this.oldData, ...updates};
		}
		AssertValidate(MTName, this.newData, `New ${MTName.toLowerCase()}-data invalid`);
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`statViews/${id}`] = this.newData;
		return updates;
	}
}