import {FBAConfig_MicroSnooze} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_MicroSnooze.js";
import {GetAudioFilesWithSubpath} from "../../../Store/main/cache.js";
import {PlaySound_ByContentUri_ForLiveSession, PlaySound_StopAll} from "../../../Utils/Bridge/Bridge_Native/MediaPlayer.js";
import {FBASession, TriggerPackage} from "../../FBASession.js";
import {AlarmsComp, AlarmsPhase} from "./AlarmsComp.js";
import {EngineSessionComp} from "./EngineSessionComp.js";

export class MicroSnoozeComp extends EngineSessionComp<FBAConfig_MicroSnooze> {
	constructor(session: FBASession, config: FBAConfig_MicroSnooze) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	GetTriggerPackages() {
		return [
			new TriggerPackage("Alarms_MicroSnooze", this.c.microSnooze_triggerSet, this, {}, async triggerInfo=>{
				this.MicroSnooze();
			}),
		];
	}

	// players
	/*microSnooze_soundPlayer = new SoundPlayer();
	microSnooze_lightPlayer = new LightPlayer();*/

	// timers
	//microSnoozeEndTimer: Timer;

	OnStart() {
		//this.microSnoozeEndTimer = new Timer(this.c.maxTimeForProgress * 1000, ()=>this.EndMicroSnooze(), 1).SetContext(this.s.timerContext);
	}
	OnStop() {
		// if tilt-snooze is active at time of session end, stop all media-player sounds (only way atm to stop micro-snooze sound)
		// todo: replace this with a better/more-customizable system
		if (this.tiltSnooze_startTime != -1) {
			PlaySound_StopAll();
		}
	}

	lastTiltAngle = -1;
	tiltSnooze_startTime = -1;
	OnTiltAngleReceived(tiltAngle_raw: number) {
		// convert raw 0-180 tilt-angle, to 0-90 tilt-angle, yielding 0 at both "flat on bed, screen up" [raw:0] and "flat on bed, screen down" [raw:180]
		const tiltAngle = Math.min(tiltAngle_raw, tiltAngle_raw.Distance(180));
		
		const prevIsTilted = this.c.tiltSnooze_enabled && this.lastTiltAngle >= this.c.tiltSnooze_minAngleFromFlat;
		const isTilted = this.c.tiltSnooze_enabled && tiltAngle >= this.c.tiltSnooze_minAngleFromFlat;
		if (isTilted && !prevIsTilted) {
			this.OnTilted();
		} else if (!isTilted && prevIsTilted) {
			this.OnUntilted()
		}

		// ongoing checks
		if (isTilted) {
			// if tilt-snooze is active (ie. not expired)
			if (this.tiltSnooze_startTime != -1) {
				// do a micro-snooze (keeps happening each tick, until tilt-snooze expires)
				this.MicroSnooze();

				// now check if the tilt-snooze should expire; if so, end the current tilt-snooze
				const tiltDuration = Date.now() - this.tiltSnooze_startTime;
				if (tiltDuration >= this.c.tiltSnooze_maxTiltDuration * 1000) {
					this.OnTiltSnoozeEnded();
				}
			}
		}

		this.lastTiltAngle = tiltAngle;
	}
	// handlers for when phone physically becomes tilted/untilted
	OnTilted() {
		// only start tilt-snooze if it's night (ie. if alarms are active)
		if (this.s.coreData.launchType == "night") {
			this.OnTiltSnoozeStarted();
		}
	}
	OnUntilted() {
		this.OnTiltSnoozeEnded();
	}
	// handlers for when a "tilt-snooze" starts/ends
	OnTiltSnoozeStarted() {
		this.tiltSnooze_startTime = Date.now();
		const soundToPlay = GetAudioFilesWithSubpath(this.c.tiltSnooze_soundPath).Random();
		if (soundToPlay) {
			PlaySound_ByContentUri_ForLiveSession(soundToPlay.contentUri, this.c.tiltSnooze_soundVolume, -1, true);
		}
	}
	OnTiltSnoozeEnded() {
		this.tiltSnooze_startTime = -1;
		PlaySound_StopAll();
	}
	
	MicroSnooze() {
		const alarmsComp = this.s.Comp(AlarmsComp);
		const wasAlarmPhase = alarmsComp?.PhaseIs(AlarmsPhase.Alarm);
		if (alarmsComp?.PhaseIs(AlarmsPhase.Alarm, AlarmsPhase.Solving)) {
			alarmsComp.NotifySolveProgress(this.c.maxTimeForProgress);
		}

		// if we were in alarm phase before this micro-snooze, stop all instances of "play random sound from folder"
		// todo: replace this with a better/more-customizable system
		if (wasAlarmPhase) {
			PlaySound_StopAll();
		}
	}

	/*OnLeavePhase_Alarm(newPhase: AlarmsPhase) {
		this.EndMicroSnooze();
	}
	microSnoozeActive = false;
	MicroSnooze() {
		this.microSnoozeActive = true;
		this.microSnoozeEndTimer.Start();
		/*this.microSnooze_soundPlayer.FindSound(this.c.phaseSleep_soundTag).Play();
		this.microSnooze_lightPlayer.FindLight(this.c.microSnooze_lightTag).Play();*#/
	}
	EndMicroSnooze() {
		this.microSnoozeActive = false;
		this.microSnoozeEndTimer.Stop();
		/*this.microSnooze_soundPlayer.Stop();
		this.microSnooze_lightPlayer.Stop();*#/
	}*/
}