import {Annotation, AnnotationsPlugin, GroupedBarsPlugin, GroupedBarsPluginOptions} from "uplot-vplugins";
import {store} from "../../../../Store/index.js";
import {JourneyStatsState, StatsXType, StatsYType} from "../../../../Store/main/tools/journey.js";
import {GetGroupedBarsPluginConfig, GroupedBarDisplayCalc} from "./GroupedBarDisplayCalc.js";
import {AggregationData, GetValMultiplierForGroupMetricNormalization} from "./AggregationData.js";
import {ALL_GROUP_NAME} from "./MetricCollector.js";
import {ShowBarText} from "./StatsChartOptions.js";
import {GetDayOffset} from "../JourneyStatsUI.js";

export function PopulateAnnotationsForChart(args: {
	width: number, height: number, chartOptions: uPlot.Options, annotations: Annotation[],
	xValues: number[], groups: string[], yValuesPerGroup: Map<string, number[]>, aggregationData: AggregationData
}) {
	const {width, height, chartOptions, annotations, xValues, groups, yValuesPerGroup, aggregationData} = args;
	const {view} = store.main.tools.journey.stats;
	
	// rect calculators (for bars only atm); synchronous calc'ing is nicer, plus had issues with uplot loopback
	//const plotArea = this.chart.current.bbox;
	//const plotArea_width = chartBodyRect.width;
	const chartLeftPadding = chartOptions.axes!.filter(a=>a.scale != "x" && a.side != 1)
		.map(a=>(a.labelSize as number) + (a.size as number)).Sum();
	const chartRightPadding = chartOptions.axes!.filter(a=>a.scale != "x" && a.side == 1)
		.map(a=>(a.labelSize as number) + (a.size as number)).Sum().KeepAtLeast(25);
	const plotAreaWidth_fromWidthAndPaddings = width - (chartLeftPadding + chartRightPadding);
	const barRectCalculator = new GroupedBarDisplayCalc({xValueCount: xValues.length, dataGroupCount: groups.length, config: GetGroupedBarsPluginConfig(), plotAreaWidth: plotAreaWidth_fromWidthAndPaddings});

	if (view.renderType == "bars" && view.yType != StatsYType.lucids_sum) { // if y-type is already lucids-sum, then the annotation adds no new info
		const xValueLucidSumSamples = xValues.map(xValue=>aggregationData.segmentIsLucidSamples.samplesForEachXValue.get(xValue) ?? []);
		for (const [index, xValue] of xValues.entries()) {
			const lucidSumSamples = xValueLucidSumSamples[index];
			for (const [i, group] of groups.entries()) {
				const barCenterX = barRectCalculator.GetBarRect(index, i).center;

				const lucidSumSamplesForGroup = lucidSumSamples.filter(a=>group == ALL_GROUP_NAME ? true : a.group == group);
				const lucidSum = lucidSumSamplesForGroup.map(a=>a.value).Sum();
				if (lucidSum == 0) continue;
				const segmentPercentThatIsLucid_raw = lucidSum / lucidSumSamplesForGroup.length;
				let segmentPercentThatIsLucid_final = segmentPercentThatIsLucid_raw * GetValMultiplierForGroupMetricNormalization(view, group, "lucidityRate");

				const baseLines = ShowBarText() ? 1 : 0;
				const getOffsetForLabelShiftedXUp = (linesShiftedUp: number)=>(-5 - (10 * linesShiftedUp)) * window.devicePixelRatio;
				if (view.showLucidCount) {
					annotations.push({
						type: "text",
						x: {pixel: barCenterX * window.devicePixelRatio},
						y: {
							value: yValuesPerGroup.get(group)![index],
							finalize: y=>y + getOffsetForLabelShiftedXUp(baseLines + (view.showLucidRate ? 1 : 0)),
						},
						text: `${lucidSum}`,
						fillStyle: "rgba(255,255,0,1)",
						font: `${10 * window.devicePixelRatio}px Arial`,
					});
				}
				if (view.showLucidRate) {
					annotations.push({
						type: "text",
						x: {pixel: barCenterX * window.devicePixelRatio},
						y: {
							value: yValuesPerGroup.get(group)![index],
							finalize: y=>y + getOffsetForLabelShiftedXUp(baseLines),
						},
						text: `${segmentPercentThatIsLucid_final.ToPercentStr(0)}`,
						fillStyle: "rgba(255,255,0,1)",
						font: `${10 * window.devicePixelRatio}px Arial`,
					});
				}
			}
		}
	}

	if (view.showDateMarkers && view.xType == StatsXType.dayOffset) {
		for (const date of view.dateMarkers) {
			if (date == null) continue;
			const xValue = GetDayOffset(date);
			const xValueIndex = xValues.indexOf(xValue);
			const prevBarRight = xValueIndex > 0 ? barRectCalculator.GetBarRect(xValueIndex - 1, groups.length - 1).right : null;
			const barLeft = barRectCalculator.GetBarRect(xValueIndex, 0).left;
			const centerOfGap = prevBarRight != null ? (prevBarRight + barLeft) / 2 : barLeft;
			//console.log("XVal:", xValue, "Date:", new Date(date), "CenterOfGap:", centerOfGap, prevBarRight, barLeft);
			
			/*annotations.push({
				type: "line",
				x: {value: xValue},
				color: "rgba(255,255,0,1)",
				lineWidth: 1,
			});*/
			annotations.push({
				type: "box",
				//xMin: {value: xValue, value_toCanvasPixels: true},
				//xMax: {value: xValue + 1},
				xMin: {
					//pixel: GetPercentFromXToY(xValues[0], xValues.Last(), xValue).ToPercentStr(),
					pixel: centerOfGap * window.devicePixelRatio,
					//finalize: a=>a + 5, // put closer to middle of gap (for typical day-count)
				},
				xSize: {
					pixel: 1 * window.devicePixelRatio,
					finalize: ()=>1 * window.devicePixelRatio, // fsr this is needed
				},
				yMin: {pixel: "0%"},
				yMax: {pixel: "100%"},
				fillStyle: "rgba(255,255,0,1)",
				drawType: "destination-over",
			});
			//console.log("XValToPos:", this.chart.current?.valToPos(xValue, "x", false), this.chart.current?.valToPos(xValue, "x", true));
		}
	}
}