import {Collection, Collection_Closed, StoreAccessor, GetDocs, QueryOp, WhereOp} from "mobx-firelink";
import {GeneralData} from "./firebase/general";
import {User} from "./firebase/users/@User";
import {UserHidden} from "./firebase/users_private/@UserHidden";
import {Activity} from "./firebase/activities/@Activity";
import {FBAConfig} from "./firebase/fbaConfigs/@FBAConfig";
import {JournalEntry} from "./firebase/journalEntries/@JournalEntry";
import {Light} from "./firebase/lights/@Light";
import {Sound} from "./firebase/sounds/@Sound";
import {TimelineEvent} from "./firebase/timelineEvents/@TimelineEvent";
import {ProcessMessage} from "./firebase/users/@ProcessMessage";
import {Shake} from "./firebase/shakes/@Shake";
import {Entity} from "./firebase/entities/@Entity";
import {Bundle} from "./firebase/bundles/@Bundle";
import {RecordingSession} from "./firebase/recordingSessions/@RecordingSession";
import {EngineSessionInfo} from "./firebase/sessions/@EngineSessionInfo";
import {TestSegment} from "./firebase/testSegments/@TestSegment";
import {Scene} from "./firebase/scenes/@Scene.js";
import {ea} from "js-vextensions";
import {StoryMessage} from "./firebase/storyMessages.js";
import {Story} from "./firebase/stories.js";
import {StatViewFull, StatView} from "./firebase/statViews/@StatView.js";

// manually import these, since otherwise they're never runtime-imported
require("./firebase/users/@User");
require("./firebase/users_private/@UserHidden");

export interface FirebaseDBShape {
	general: Collection_Closed<{data: GeneralData}>;
	modules: Collection_Closed<{
		// feedback: FeedbackDBShape;
	}>;

	activities: Collection<Activity>;
	bundles: Collection<Bundle>;
	entities: Collection<Entity>;
	fbaConfigs: Collection<FBAConfig>;
	journalEntries: Collection<JournalEntry>;
	lights: Collection<Light>;
	stories: Collection<Story>;
	storyMessages: Collection<StoryMessage>;
	recordingSessions: Collection<RecordingSession>;
	sessions: Collection<EngineSessionInfo>;
	scenes: Collection<Scene>;
	shakes: Collection<Shake>;
	statViews: Collection<StatView>;
	sounds: Collection<Sound>;
	testSegments: Collection<TestSegment>;
	//timelines: Collection<Timeline>;
	timelineEvents: Collection<TimelineEvent>;
	users: Collection<User>;
	users_private: Collection<UserHidden>;
	userProcessMessages: Collection<ProcessMessage>;
}

export const GetAuth = StoreAccessor(s=>()=>{
	return s.firelink.userInfo;
});
export const GetAuth_Raw = StoreAccessor(s=>()=>{
	return s.firelink.userInfo_raw;
});

// helpers for entries (images, sounds, shakes, lights)
// ==========

export type Entry = Entity | Sound | Shake | Light;
export type EntryType = typeof Entity | typeof Sound | typeof Shake | typeof Light;

export const GetEntriesByNameOrTag = StoreAccessor(s=>(type: EntryType, nameOrTag: string)=>{
	const byName = GetEntriesByName(type, nameOrTag);
	const byTag = GetEntriesByTag(type, nameOrTag);
	return byName.concat(byTag).Distinct();
});
export const GetEntriesByName = StoreAccessor(s=>(type: EntryType, name: string): Entry[]=>{
	const queryOps = [
		new WhereOp("name", "==", name),
	] as QueryOp[];
	if (type == Entity) return GetDocs({queryOps}, a=>a.entities);
	if (type == Sound) return GetDocs({queryOps}, a=>a.sounds);
	if (type == Shake) return GetDocs({queryOps}, a=>a.shakes);
	if (type == Light) return GetDocs({queryOps}, a=>a.lights);
	return ea;
});
export const GetEntriesByTag = StoreAccessor(s=>(type: EntryType, tag: string): Entry[]=>{
	const queryOps = [
		new WhereOp("tags", "array-contains", tag),
	] as QueryOp[];
	if (type == Entity) return GetDocs({queryOps}, a=>a.entities);
	if (type == Sound) return GetDocs({queryOps}, a=>a.sounds);
	if (type == Shake) return GetDocs({queryOps}, a=>a.shakes);
	if (type == Light) return GetDocs({queryOps}, a=>a.lights);
	return ea;
});