import {RunInAction, weekInMS} from "web-vcore";
import {Assert, Clone, E, Timer} from "js-vextensions";
import {default as moment} from "moment";
import {AddSession} from "../Server/Commands/AddSession.js";
import {AddTimelineEvent} from "../Server/Commands/AddTimelineEvent.js";
import {StopCurrentTimelineEvents} from "../Server/Commands/StopCurrentTimelineEvents.js";
import {GetActivities} from "../Store/firebase/activities.js";
import {AutoEndType, FBAConfig} from "../Store/firebase/fbaConfigs/@FBAConfig";
import {UpdateLocalSessionsMap} from "../Store/firebase/sessions.js";
import {EngineSessionInfo} from "../Store/firebase/sessions/@EngineSessionInfo.js";
import {TimelineEvent} from "../Store/firebase/timelineEvents/@TimelineEvent.js";
import {LogType} from "../UI/Tools/@Shared/LogEntry.js";
import {InDesktop} from "../Utils/Bridge/Bridge_Native.js";
import {DeleteSessionFolder, SaveSessionGeneralData} from "../Utils/Bridge/Bridge_Preload.js";
import {ClearAndStopLiveFBASession, FBASession, liveFBASession} from "./FBASession";
import {SessionEvent} from "./FBASession/SessionEvent.js";
import {AssertNotify} from "../Utils/General/General.js";
import {Setting} from "../Store/firebase/fbaConfigs/@EngineConfig/@Shared/Setting.js";
import _ from "lodash";

/*//const ORM_EncoderWorker = require("worker-loader!opus-media-recorder/encoderWorker.js");
const ORM_EncoderWorker = require("worker-loader!opus-media-recorder/encoderWorker.umd.js"); // umd version needed for webpack 5
/*const OMR_OggOpusWasm = require("opus-media-recorder/OggOpusEncoder.wasm");
//const OMR_WebMOpusWasm = require("opus-media-recorder/WebMOpusEncoder.wasm");*/
// async wrapper
/*let OMR_OggOpusWasm;
void (async function() {
	OMR_OggOpusWasm = await import("opus-media-recorder/OggOpusEncoder.wasm");
}());*#/*/

export type MicLoudnessListener = (notifyLoudness: number, actualLoudness: number) => any;

// the desktop bridge merely passed on the message from a remote android instance
/*nativeBridge.RegisterFunction("Remote_SnoozeFail", ()=>{
	if (fbaCurrentSession && fbaCurrentSession.IsLocal()) {
		console.log("Got snooze call from remote, android!");
		fbaCurrentSession.Snooze("Snooze (remote, android)");
		return "Applied.";
	}
});*/

export function ChangeTimestampDateToBeAfterNow(timestamp: number) {
	const yesterdayDateStr = moment().subtract(1, "day").format("YYYY-MM-DD");
	const timeOfDayStr = moment(timestamp).format("HH:mm:ss");
	let modifiedTimestamp = moment(`${yesterdayDateStr} ${timeOfDayStr}`);

	// keep increasing the time by 1 day, until it is past the current time (should generally only take 1 or 2 iterations)
	while (modifiedTimestamp.valueOf() < Date.now()) {
		modifiedTimestamp = modifiedTimestamp.add(1, "day");
	}

	return modifiedTimestamp.valueOf();
}

export class FBASession_Local extends FBASession {
	/*constructor(config: FBAConfig) {
		super(config);
		this.snoozeAndPrompts.memoryPrompt.s = this;
	}*/

	/** Packages some data together, to be stored in a TimelineEvent (or sent to an evaluator). */
	GetSessionInfo(includeLog = false): EngineSessionInfo {
		AssertNotify(this.id != null, "Session ID should not be null."); // hit
		const result = new EngineSessionInfo(E(
			{
				id: this.id,
				creator: this.userID,
				configID: this.c._key,
				config: Clone(this.c),
				launchType: this.coreData.launchType,

				settingValuesSelected: Object.entries(this.coreData.settingValuesSelected).ToMapObj(a=>a[0], a=>a[1].value),
				alarmDelay: this.c.alarms.alarmDelay_lockPerSession ? this.coreData.journey_alarmDelay : undefined,
				alarms_mainSequence: this.c.alarms.phaseAlarm_sequences_lockPerSession ? this.coreData.alarms_currentSequence : null,

				startTime: this.coreData.startTime,
				localOffsetFromUTC: this.localOffsetFromUTC,
				endTime: this.endTime,
				week: moment(this.coreData.startTime).utc().startOf("week").valueOf(),
				events: this.events,
			},
			includeLog && {logEntries: this.logEntries},
		));
		// add _folderName prop, but non enumerably/serializably
		result.VSet("_folderName", this.folderName, {prop: {enumerable: false}});
		return result;
	}
	// this is split into its own function, to allow re-calling through console (by power-users), if issue occurs the first time
	async FinalizeStoredData(saveLocally: boolean, saveOnline: boolean, allowAddTimelineEvent = true) {
		const sessionInfo = this.GetSessionInfo()!;

		if (InDesktop()) {
			if (saveLocally) {
				SaveSessionGeneralData(sessionInfo._folderName, sessionInfo, this.logEntries);
			} else {
				// place session-folder in trash (exists from log-file writing)
				DeleteSessionFolder(sessionInfo._folderName);
			}
			UpdateLocalSessionsMap();
		}

		// add event marking fba-session
		if (saveOnline) {
			const sessionID_final = await this.UploadSessionInfo(sessionInfo, allowAddTimelineEvent);
			sessionInfo.id = sessionID_final;
			this.Log(`Session saved online, as id: ${sessionID_final}`, LogType.Event_Large);

			// save session-info locally again (since final session-id was obtained)
			if (InDesktop() && saveLocally) {
				SaveSessionGeneralData(sessionInfo._folderName, sessionInfo, this.logEntries);
				UpdateLocalSessionsMap(false, [sessionInfo._folderName!]);
			}
		}
	}
	timelineEvent: TimelineEvent;
	//timelineEventID: string;
	async UploadSessionInfo(sessionInfo: EngineSessionInfo, addTimelineEvent = true) {
		const sessionID = await new AddSession({session: sessionInfo}).Run();

		const fbaActivity = GetActivities(this.userID).find(a=>a.name == "FBA session");
		if (fbaActivity != null && addTimelineEvent) {
			this.timelineEvent = new TimelineEvent({
				timeline: this.userID, activity: fbaActivity._key,
				startTime: this.coreData.startTime, endTime: this.endTime,
				sessionID,
			});
			this.timelineEvent._key = await new AddTimelineEvent({event: this.timelineEvent}).Run();
			//await new UpdateTimelineEvent({id: this.timelineEvent._key, updates: {sessionInfo}}).Run();
			//await new StopTimelineEvent({id: this.timelineEvent._key}).Run();
			await new StopCurrentTimelineEvents({timelineID: this.userID}).Run(); // stop all, as cleanup for any left-over from earlier
		}

		return sessionID;
	}

	// timers
	initialDelayTimer: Timer;
	autoEndDelayTimer: Timer;

	CreateTimers() {
		const initialDelay_final = this.coreData.launchType == "day" ? weekInMS : this.c.general.initialDelay;
		this.initialDelayTimer = new Timer(initialDelay_final, async()=>{
			this.Log("Initial delay completed.", LogType.Event_Large);
			this.AddEvent({type: "General.InitialDelayEnd"});
			this.Broadcast({}, a=>a.OnInitialDelayCompleted);
		}, 1).SetContext(this.timerContext);

		let autoEndDelayInMS: number;
		if (this.c.general.autoEnd_type == AutoEndType.AtTimeOfDay) {
			// the auto-end-time value includes the date (of when the field's value was set by user); replace that date with the next one that makes the marked time-of-day be in the future 
			const endTime = ChangeTimestampDateToBeAfterNow(this.c.general.autoEnd_time);
			autoEndDelayInMS = endTime - Date.now();
		} else if (this.c.general.autoEnd_type == AutoEndType.AfterDuration) {
			autoEndDelayInMS = this.c.general.autoEnd_delay;
		}

		this.autoEndDelayTimer = new Timer(autoEndDelayInMS!, ()=>{
			this.Log("Auto-end duration completed, thus stopping the session.", LogType.Event_Large);
			Assert(liveFBASession == this, "Auto-end timer triggered, but the session it triggered for was not the live session!");
			ClearAndStopLiveFBASession(true, true);
		}, 1).SetContext(this.timerContext);
	}

	ModifyLiveAutoEndTime(newEndTime: number) {
		Assert(this.autoEndDelayTimer.Enabled, "Cannot modify live-session's auto-end time, since the auto-end timer is not enabled!");
		this.autoEndDelayTimer.intervalInMS = newEndTime - Date.now();
		this.autoEndDelayTimer.Start(); // restart with new timer-interval (in this case, really a one-time delay rather than an "interval")
	}

	// events
	// ==========

	// events stored in FBASession_Local, because events are only for record-keeping purposes, thus not useful for remote sessions
	protected events = [] as SessionEvent[];
	get EventsCopy() { return this.events.slice(); } 
	AddEvent(eventData: Partial<SessionEvent>) {
		const event = new SessionEvent(eventData);
		this.events.push(event);
		RunInAction("AddEvent", ()=>this.coreData.events.push(event));
	}

	GetSleepCycleInfo() {
		return GetSleepCycleInfoFromEvents(this.events);
	}
}

export function GetSleepCycleInfoFromEvents(events: SessionEvent[]) {
	// Why not just count the "CycleStart" events? Because journey-cycles are different from sleep-cycles.
	// Sleep-cycles are not 100% defined yet, but each *should* start at a moment roughly shared between session and DJ entry:
	// * Time of session's "ResleepEnd" event.
	// * Time of wake-segment start-time.
	// (almost same moment: resleep/alarm-wait end triggers wake-segment creation soon after, through "Mark awareness after alarm-wait" feature)
	const sleepCycleEnders = events.filter(a=>a.type == "General.SleepCycleEnd");
	const cycleNumber = 1 + sleepCycleEnders.length; // session begins "in cycle 1", not 0
	const lastCycleEnderEvent = sleepCycleEnders.LastOrX();
	const lastCycleEnderEventIndex = events.indexOf(lastCycleEnderEvent as any);
	const eventsThisCycle = lastCycleEnderEventIndex != -1
		? events.slice(lastCycleEnderEventIndex + 1)
		: events;
	return {cycleNumber, lastCycleEnderEvent, lastCycleEnderEventIndex, eventsThisCycle};
}