import {Assert, GetValues, GetValues_ForSchema, ModifyString} from "js-vextensions";
import {ignore} from "mobx-sync";
import {O, RunInAction} from "web-vcore";
import {computed, makeObservable} from "mobx";
import {OnSessionEnded} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {store} from "../../index.js";
import moment from "moment";
import {AddSchema} from "mobx-firelink";
import {StatViewFull} from "../../firebase/statViews/@StatView.js";

export enum JourneyTab {
	history = "history",
	stats = "stats",
	config = "config",
	log = "log",
	engine = "engine",
}

export enum BottomPanelSubpanel {
	none = "none",
	entities = "entities",
	transcribe = "transcribe",
	llm = "llm",
}

export type EntityCategory = "people" | "objects" | "concepts";

/*export const StatsXType_entries = ["dayOffset", "cycleNumber"] as const;
export type StatsXType = typeof StatsXType_entries[number];*/
export enum StatsXType {
	showAll = "showAll",
	dayOffset = "dayOffset",
	cycleInGroup = "cycleInGroup", // note: only counts "dream" sleep-cycles
	cycleInNight = "cycleInNight",
}
AddSchema("StatsXType", {enum: GetValues(StatsXType)});

export function StatsXType_Label(xType: StatsXType) {
	if (xType == "showAll") return "(show all)";
	if (xType == "dayOffset") return "Day offset";
	if (xType == "cycleInGroup") return "Cycle (in group)";
	if (xType == "cycleInNight") return "Cycle (in night)";
	return ModifyString(xType, m=>[m.startLower_to_upper, m.lowerUpper_to_lowerSpaceLower]);
}

/*export const StatsYType_entries = ["termsInShortText", "termsInLongText"] as const;
export type StatsYType = typeof StatsYType_entries[number];*/
export enum StatsYType {
	combined = "combined",
	dreamSegments_sum = "dreamSegments_sum",
	lucids_sum = "lucids_sum",
	dreamsMatching_sum = "dreamsMatching_sum",
	termsInShortText = "termsInShortText",
	termsInShortText_sum = "termsInShortText_sum",
	termsInLongText = "termsInLongText",
	termsInLongText_sum = "termsInLongText_sum",
	responseTime = "responseTime",
	linkVoicings_sum = "linkVoicings_sum",
	linkVisualizations_sum = "linkVisualizations_sum",
	realityChecks_sum = "realityChecks_sum",
	quizPrompts_sum = "quizPrompts_sum",
	//quizTryHitPercent = "quizTryHitPercent",
	quizFirstTryHitPercent = "quizFirstTryHitPercent",
	quizTimeTillSuccess = "quizTimeTillSuccess",
}
AddSchema("StatsYType", {enum: GetValues(StatsYType)});

// todo: probably change UI to show "tag" instead of "entity" everywhere (more common/understandable term)
export function StatsYType_Label(yType: StatsYType) {
	if (yType == "combined") return "Combined";
	if (yType == "dreamSegments_sum") return "Dream segments (sum)";
	if (yType == "lucids_sum") return "Lucids (sum)";
	if (yType == "dreamsMatching_sum") return "Dreams matching (sum)";
	if (yType == "termsInShortText") return "Tags (typical)";
	if (yType == "termsInShortText_sum") return "Tags (sum)";
	if (yType == "termsInLongText") return "Words (typical)";
	if (yType == "termsInLongText_sum") return "Words (sum)";
	if (yType == "responseTime") return "Response time [s] (typical)";
	if (yType == "linkVoicings_sum") return "Link voicings (sum)";
	if (yType == "linkVisualizations_sum") return "Link visualizations (sum)";
	if (yType == "realityChecks_sum") return "Reality checks (sum)";
	if (yType == "quizPrompts_sum") return "Quiz prompts (sum)";
	//if (yType == "quizTryHitPercent") return "Quiz try hit-% (typical)";
	if (yType == "quizFirstTryHitPercent") return "Quiz first-try hit-% (typical)";
	if (yType == "quizTimeTillSuccess") return "Quiz time-till-success [s] (typical)";
	return ModifyString(yType, m=>[m.startLower_to_upper, m.lowerUpper_to_lowerSpaceLower]);
}

export enum StatsGrouping {
	none = "none",
	alarmSequence = "alarmSequence",
	alarmDelay = "alarmDelay",
	volumeMod = "volumeMod",
	alarmRestartIntervalMod = "alarmRestartIntervalMod",
}
AddSchema("StatsGrouping", {enum: GetValues(StatsGrouping)});

export class JourneyState {
	constructor() { makeObservable(this); }

	@O tab = JourneyTab.engine;

	// stats
	@O stats = new JourneyStatsState();

	// engine
	@O @ignore locked = false;
	@O @ignore canvasLocked = false;
	@O pastJournalEntriesToShow = 0;
	@O @ignore showAnchorEntities = false;
	@O showEventBoxes = true;
	@O showDreamEntities = true;
	@O allowScreenlessEntityAdding = true;

	// bottom panel
	@O showBottomPanel = true;
	@O bottomPanelSubpanel = BottomPanelSubpanel.none;
	@O entities = new EntitiesSubpanelState();
	@O transcribe = new TranscribeSubpanelState();
	@O llm = new LLMSubpanelState();
}

export const GraphRenderType_values = ["bars", "spline", "linear", "stepped"] as const;
export type GraphRenderType = typeof GraphRenderType_values[number];
AddSchema("GraphRenderType", {enum: GraphRenderType_values as any});

export enum SmoothingType {
	"previous" = "previous",
	"centered" = "centered",
}
AddSchema("SmoothingType", {enum: GetValues(SmoothingType)});

export class JourneyStatsState {
	constructor() { makeObservable(this); }

	@O view = new StatViewFull();
}

export class EntitiesSubpanelState {
	constructor() { makeObservable(this); }

	// ui-layout fields
	@O @ignore entityCellContainerWidth = 0;
	@O @ignore entityCellColumns = 9;
	@O @ignore searchText = "";
}
export class TranscribeSubpanelState {
	constructor() { makeObservable(this); }

	@O transcribeText = "";

	@O @ignore forceShowTranscribeText = false;
}
export class LLMSubpanelState {
	constructor() { makeObservable(this); }

	@O panelHeight = 40;
	@O showLLMOutputOnSide = false;
	@O llmPresets = [] as LLMPreset[];

	@O customLLMInput = "";
	@O customLLMInput_includeSystemPrompt = true;
}

export class LLMPreset {
	name: string;
	text: string;
}
export function GetDefaultLLMPresets() {
	const presets = [
		{name: "ShowNext", text: "LLM, show me what happens next."},
		{name: "SpeakMyself", text: "LLM, remember to never automatically speak or act for me. If they ask me a question, stop the generation there and allow me to respond."},
		{name: "Shrug", text: `"🤷‍♂️"`},
		{name: "FillDyn", text: "LLM, you forgot to populate the actual content for the dynamic options! Of course, also list all of the non-dynamic options like normal."},
	];
	return presets;
}

export const entityCellWidthTarget = 40;
export const entityCellAspectRatio = "1/1";

//export const entityCellWidthTarget = 60;
//export const entityCellAspectRatio = "3/2";