import {SleepAsync} from "js-vextensions";
import {Button, Text, TextArea} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {Observer, RunInAction, RunInAction_Set} from "web-vcore";
import React from "react";
import {store} from "../../../../Store/index.js";
import {nativeBridge} from "../../../../Utils/Bridge/Bridge_Native.js";
import {TranscribeTarget} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamTranscribe.js";
import {useAutoUnregisterWhisperTranscriptionWatcher, WhisperTranscriptionWatcher} from "../../../../Utils/Bridge/Whisper.js";
import {GetLiveLocalSession, liveFBASession} from "../../../../Engine/FBASession.js";
import {DreamTranscribeComp} from "../../../../Engine/FBASession/Components/DreamTranscribeComp.js";
import {WaitXThenRun} from "js-vextensions";

@Observer
export class TranscribePanel_Header extends BaseComponent<{}, {}> {
	whisperTranscriptionWatcher = new WhisperTranscriptionWatcher({
		processingPriority: 10,
		absorbsResult: true,
		keepsRecordingAlive: true,
		onChunkTranscribed: ({text})=>{
			if (text.trim().length == 0) return null;

			const liveLocalSession = GetLiveLocalSession();
			const dreamTranscribeComp = liveLocalSession?.Comp(DreamTranscribeComp);
			// we only do processing in this handler if there is no dream-transcribe comp that is active (since this is just for usage when session is inactive)
			if (dreamTranscribeComp == null || dreamTranscribeComp.IsSuspended()) {
				const uiState = store.main.tools.journey.transcribe;
				RunInAction_Set(this, ()=>uiState.transcribeText = uiState.transcribeText + text + "\n");
			}
		},
	});
	
	render() {
		let {} = this.props;
		const uiState = store.main.tools.journey.transcribe;

		useAutoUnregisterWhisperTranscriptionWatcher(this.whisperTranscriptionWatcher);

		return (
			<>
				<Button text={this.whisperTranscriptionWatcher.IsRegistered() ? "Stop recording" : "Start recording"} onClick={async()=>{
					const newTranscribing = !this.whisperTranscriptionWatcher.IsRegistered();
					if (newTranscribing) {
						this.whisperTranscriptionWatcher.Register();
					} else {
						this.whisperTranscriptionWatcher.UnregisterAfterTranscribedToNow();
					}
				}}/>
			</>
		);
	}
}

export function GetTranscribePanelHeightOverride() {
	const uiState = store.main.tools.journey.transcribe;
	if (liveFBASession?.Comp(DreamTranscribeComp).GetCurrentTranscriptionTarget() == TranscribeTarget.dreamSegment && !uiState.forceShowTranscribeText) return 50;
	return null;
}

@Observer
export class TranscribePanel extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.journey.transcribe;

		const transcribeToSegment = liveFBASession?.Comp(DreamTranscribeComp).GetCurrentTranscriptionTarget() == TranscribeTarget.dreamSegment;
		return <>
			{transcribeToSegment &&
			<Text style={{textAlign: "center"}} onClick={()=>RunInAction_Set(this, ()=>uiState.forceShowTranscribeText = !uiState.forceShowTranscribeText)}>
				Transcribing is being redirected to the last-ended dream-segment. (click to show/hide panel anyway)
			</Text>}
			{(!transcribeToSegment || uiState.forceShowTranscribeText) &&
			<TextArea style={{flex: 1}} value={uiState.transcribeText} onChange={val=>RunInAction_Set(this, ()=>uiState.transcribeText = val)}/>}
		</>;
	}
}