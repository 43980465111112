import moment from "moment";
import React from "react";
import {Button, CheckBox, Column, DropDown, DropDownContent, DropDownTrigger, Pre, Row, Select, Spinner, Text, TextArea, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {InfoButton, Observer, RunInAction, RunInAction_Set, TextPlus, VDateTime} from "web-vcore";
import {store} from "../../../../Store/index.js";
import {GraphRenderType_values, SmoothingType, StatsGrouping, StatsXType} from "../../../../Store/main/tools/journey.js";
import {zIndexes} from "../../../../Utils/UI/ZIndexes.js";
import {ScrollView} from "react-vscrollview";
import {InAndroid} from "../../../../Utils/Bridge/Bridge_Native.js";
import {GetStatViews} from "../../../../Store/firebase/statViews.js";
import {MeID} from "../../../../Store/firebase/users.js";
import {StatViewFull, StatView, StatView_NormalKeys} from "../../../../Store/firebase/statViews/@StatView.js";
import {Clone} from "js-vextensions";
import {AddStatView} from "../../../../Server/Commands/AddStatView.js";
import {UpdateStatView} from "../../../../Server/Commands/UpdateStatView.js";
import {ShowMessageBox} from "react-vmessagebox";
import {DeleteStatView} from "../../../../Server/Commands/DeleteStatView.js";
import {SortKeysInObjectTree} from "../../../../Utils/General/General.js";
import {GetSchemaJSON} from "mobx-firelink";

@Observer
export class JourneyStatsViewsDropdown extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.journey.stats;
		const {view: currentView} = uiState;
		const meID = MeID();
		if (meID == null) return null;
		const views = GetStatViews(meID).OrderBy(a=>a.createdAt).OrderBy(a=>a.name);

		return (
			<DropDown style={{marginLeft: "auto", marginRight: 5, position: "relative"}}>
				<DropDownTrigger>
					<Button mdIcon="folder-cog" size={32}/>
				</DropDownTrigger>
				<DropDownContent style={{
					zIndex: zIndexes.dropDown, position: "fixed", right: 0,
					top: window.innerWidth < 800 ? 60 : 32, // if screen-width <800px, setting-button probably got pushed to second row
					//bottom: 0, // bottom:0 = stop at bottom of screen
					background: "rgba(0,0,0,.9)", borderRadius: 5,
				}}>
					<Column>
						<Row>
							<Text>Views:</Text>
							<Button ml={5} mdIcon="plus" onClick={()=>{
								const view = Clone(currentView);
								view.name = "(new view)";
								new AddStatView({entry: view}).Run();
							}}/>
						</Row>
						{/*<ScrollView style={{flex: 1}}>*/}
						{views.map((view, index)=>{
							return (
								<Row key={view._key} style={{padding: "5px 0"}}>
									<Button text={view.name} style={{flex: 1}} onClick={()=>{
										// for each property in view, apply it to the current view
										RunInAction("ViewsDropdown.ApplyClickedView", ()=>{
											for (const [key, storedVal] of Object.entries(view)) {
												currentView[key] = storedVal;
											}
										});
									}}/>
									<StatViewSettingsDropdown viewInDB={view}/>
								</Row>
							);
						})}
					</Column>
				</DropDownContent>
			</DropDown>
		);
	}
}

@Observer
class StatViewSettingsDropdown extends BaseComponent<{viewInDB: StatView}, {editing: boolean}> {
	render() {
		let {viewInDB} = this.props;
		let {editing} = this.state;
		const uiState = store.main.tools.journey.stats;
		const {view: currentView} = uiState;
		const meID = MeID();
		if (meID == null) return null;

		return (
			<DropDown style={{marginLeft: 5}}>
				<DropDownTrigger>
					<Button mdIcon="cog"/>
				</DropDownTrigger>
				<DropDownContent style={{
					zIndex: zIndexes.dropDown, position: "fixed", right: 0,
					background: "rgba(0,0,0,.9)", borderRadius: 5,
				}}>
					<Column style={{padding: 10}}>
						<Row>
							<Text>Name: </Text>
							<TextInput style={{width: 200}} value={viewInDB.name} onChange={val=>{
								new UpdateStatView({id: viewInDB._key!, updates: {name: val}}).Run();
							}}/>
						</Row>
						<Button mt={5} text="Overwrite with current (all fields)" style={{fontSize: 12}} onClick={()=>{
							ShowMessageBox({
								title: "Overwrite stored view with current view (all fields)", cancelButton: true,
								message: `Overwrite stored view "${viewInDB.name}" with current view? (all fields)`,
								onOK: ()=>{
									const viewUpdates = GetPropertySubsetOfView(currentView, StatView_NormalKeys(), true, viewInDB);
									new UpdateStatView({id: viewInDB._key!, updates: viewUpdates}).Run();
								},
							});
						}}/>
						<Button mt={5} text="Overwrite with current (existing fields)" style={{fontSize: 12}} onClick={()=>{
							ShowMessageBox({
								title: "Overwrite stored view with current view (existing fields)", cancelButton: true,
								message: `Overwrite stored view "${viewInDB.name}" with current view? (existing fields)`,
								onOK: ()=>{
									const viewUpdates = GetPropertySubsetOfView(currentView, Object.keys(viewInDB), true, viewInDB);
									new UpdateStatView({id: viewInDB._key!, updates: viewUpdates}).Run();
								},
							});
						}}/>
						<Button mt={5} text="Edit JSON" style={{fontSize: 12}} onClick={()=>{
							//let newJSON = ToJSON_WithSpaces(config);
							const configCopy = SortKeysInObjectTree(viewInDB);
							let newJSON = JSON.stringify(configCopy, undefined, 4);
							const boxController = ShowMessageBox({
								title: `Edit json for view "${viewInDB.name}"`, cancelButton: true,
								message: ()=>(
									//<Column style={DialogStyle({width: 800})}>
									<Column>
										<Row>View:</Row>
										<TextArea style={{width: 800..KeepAtMost(window.innerWidth - 100), height: window.innerHeight - 200}} value={newJSON} onChange={val=>{
											newJSON = val;
											boxController.UpdateUI();
										}}/>
									</Column>
								),
								okOnEnterKey: false, // enter key needed for adding new-lines!
								onOK: ()=>{
									const newJSONObj = JSON.parse(newJSON);
									new UpdateStatView({id: viewInDB._key!, newData: newJSONObj}).Run();
								},
							});
						}}/>
						<Button mt={5} text="Delete view" style={{fontSize: 12}} onClick={()=>{
							ShowMessageBox({
								title: "Delete view", cancelButton: true,
								message: `Delete view "${viewInDB.name}"?`,
								onOK: ()=>{
									new DeleteStatView({id: viewInDB._key!}).Run();
								},
							});
						}}/>
					</Column>
				</DropDownContent>
			</DropDown>
		);
	}
}

export function GetPropertySubsetOfView(view: StatView, propertyNames: string[], excludeLockedProps: boolean, ignorePropsUnchangedFromX?: StatView) {
	if (excludeLockedProps) propertyNames = propertyNames.Exclude("creator", "createdAt");
	
	const viewSubset = {} as StatView;
	for (const [key, val] of Object.entries(view)) {
		if (!propertyNames.includes(key)) continue;
		if (ignorePropsUnchangedFromX != null && ignorePropsUnchangedFromX[key] == val) continue;
		viewSubset[key] = view[key];
	}
	return viewSubset;
}