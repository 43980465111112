import {AssertV, Command} from "mobx-firelink";
import {GetStatView} from "../../Store/firebase/statViews.js";
import {IsUserCreatorOrMod} from "../../Store/firebase/users/$user";

export class DeleteStatView extends Command<{id: string}> {
	Validate() {
		const {id} = this.payload;
		const oldData = GetStatView(id);
		AssertV(IsUserCreatorOrMod(this.userInfo?.id, oldData), "Insufficient permissions.");
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`statViews/${id}`] = null;
		return updates;
	}
}