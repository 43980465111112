import {Link, RunInAction, RunInAction_Set} from "web-vcore";
import moment from "moment";
import {Button, CheckBox, Column, Div, Grid, Row, Select, Text, TextInput} from "react-vcomponents";
import {ScrollView} from "react-vscrollview";
import {AddJournalEntry} from "../../Server/Commands/AddJournalEntry.js";
import {GetJournalEntryAllText, GetTermsInDreamSegmentText, JournalEntry, JournalSegment} from "../../Store/firebase/journalEntries/@JournalEntry.js";
import {Me} from "../../Store/firebase/users.js";
import {store} from "../../Store/index.js";
import {ES} from "../../Utils/UI/GlobalStyles.js";
import {ShowSignInPopup} from "../@Shared/NavBar/UserPanel.js";
import {BaseComponent} from "react-vextensions";
import {JournalDisplayMode} from "../../Store/main/journal.js";
import {GetEntries} from "js-vextensions";
import React, {useState} from "react";
import {observer} from "mobx-react";
import {E, StartDownload} from "js-vextensions";
import {EntryListSettingsDropdown} from "./EntryList/EntryListSettingsDropdown.js";
import {DoesSegmentMatchFilter, JournalEntryPreview} from "./JournalEntryPreview.js";
import {GetDreamEvents} from "../../Store/firebase/journalEntries.js";

export class JournalDisplayModeSwitcher extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.journal;
		return (
			<Select displayType="button bar" options={GetEntries(JournalDisplayMode, "ui")}
				value={uiState.displayMode} onChange={val=>RunInAction_Set(this, ()=>uiState.displayMode = val)}/>
		);
	}
}

export const GetEntriesToShow_EntryList = (entries: JournalEntry[])=>{
	const filter = store.main.journal.filter;
	const filterWords = filter.text_include.toLowerCase().split(" ").filter(a=>a.trim().length);
	return entries.filter(entry=>{
		if (filter.showOnlyLucids && !entry.segments.Any(a=>a.lucid)) return false;
		if (filterWords.length) {
			const allText = GetJournalEntryAllText(entry);
			if (!filterWords.every(word=>allText.toLowerCase().includes(word))) return false;
		}
		const foundMatchForSegmentFilters = entry.segments.some(segment=>{
			// Why do we set applyGeneralTextFiltering=false here? Because if we're searching for two terms, but
			// ...the matches are split between two segments, we still want the journal-entry itself to match/show.
			return DoesSegmentMatchFilter(segment, filter, false);
		});
		if (!foundMatchForSegmentFilters) return false;
		return true;
	});
};

export const EntryList = observer((props: {entries: JournalEntry[]})=>{
	const {entries} = props;
	const uiState1 = store.main.journal;
	const uiState2 = store.main.tools.journey;

	const entriesToShow = GetEntriesToShow_EntryList(entries);

	return (
		<Column style={ES({height: "100%", flex: 30})}>
			<Row className="clickThrough" style={{flexWrap: "wrap", gap: 5, padding: 10, background: "rgba(0,0,0,.7)", borderRadius: "10px 10px 0 0"}}>
				<JournalDisplayModeSwitcher/>
				<CheckBox text="Text wrap" value={uiState1.textPreviewMultiline} onChange={val=>RunInAction_Set(()=>uiState1.textPreviewMultiline = val)}/>
				<CheckBox text="Only lucids" value={uiState1.filter.showOnlyLucids} onChange={val=>RunInAction_Set(()=>uiState1.filter.showOnlyLucids = val)}/>
				<Row>
					<Text>Filter:</Text>
					<TextInput ml={5} style={{width: 200}} instant value={uiState1.filter.text_include} onChange={val=>RunInAction_Set(()=>uiState1.filter.text_include = val)}/>
				</Row>

				<Row ml="auto">
					<Button size={32} mdIcon="plus" style={{justifySelf: "right"}} onClick={async()=>{
						if (Me() == null) return ShowSignInPopup();
						const entry = new JournalEntry({
							title: "New entry",
							wakeTime: Date.now(),
							segments: [
								new JournalSegment({
									wakeTime: Date.now(),
								}),
							],
						});
						const entryID = await new AddJournalEntry({entry}).Run();
						RunInAction("JournalEntryList.addEntry.onClick", ()=>store.main.journal.selectedJournalEntry = entryID);
					}}/>
					<Button ml={3} size={32} mdIcon={uiState2.showAnchorEntities ? "image" : "image-frame"} onClick={()=>{
						RunInAction_Set(()=>uiState2.showAnchorEntities = !uiState2.showAnchorEntities);
					}}/>
					<EntryListSettingsDropdown entries={entries}/>
				</Row>
			</Row>
			<ScrollView style={{flex: 1}} contentStyle={E(
				{backgroundColor: "rgba(30,30,30,.7)", borderRadius: "0 0 10px 10px"},
				uiState1.textPreviewMultiline && {minWidth: null},
			)} onClick={e=>{
				if (e.target != e.currentTarget) return;
				RunInAction("JournalEntryList.background.onClick", ()=>store.main.journal.selectedJournalEntry = null);
			}}>
				{entriesToShow.length == 0 && <div style={{textAlign: "center", fontSize: 18}}>Loading...</div>}
				{entriesToShow.map((entry, index)=>{
					return <JournalEntryPreview key={entry._key} entry={entry} entriesToShow={entriesToShow} journalPage={true}/>;
				})}
			</ScrollView>
		</Column>
	);
});