import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";

export enum SleepPosition {
	"" = null as any, // looking back: what is this for?
	Back = 10,
	Front = 20,
	LeftSide = 30,
	RightSide = 40,
}

export class JournalEntry {
	constructor(initialData?: Partial<JournalEntry>) {
		this.Extend({
			title: "",
			notes: "",
			segments: [new JournalSegment()],
		});
		this.Extend(initialData);
	}
	_key: string;
	creator: string;
	createdAt: number;

	title: string;
	tags: string[];
	sleepTime: number|n;
	wakeTime: number|n;
	wakePosition: SleepPosition;
	notes: string;
	segments: JournalSegment[];
}
AddSchema("JournalEntry", {
	properties: {
		creator: {type: "string"},
		createdAt: {type: "number"},

		title: {type: "string"},
		tags: {items: {type: "string"}},
		sleepTime: {type: ["null", "number"]},
		wakeTime: {type: ["null", "number"]},
		wakePosition: {oneOf: GetValues_ForSchema(SleepPosition)},
		notes: {type: "string"},
		segments: {items: {$ref: "JournalSegment"}},
	},
});

//export const EntitySequence_RowSplitter_ID = "<EntitySequence_RowSplitter_ID>";
export class JournalSegment {
	constructor(data?: Partial<JournalSegment>) {
		//this.Extend({shortText: "", longText: ""});
		//this.Extend(initialData);
		Object.assign(this, data);
	}

	lucid: boolean;
	semiLucid: boolean;
	ordered: boolean;
	length: number; // in seconds
	wakeTime: number|n; // if this is non-null, then this "segment" is just a "wake marker"; should be displayed in UI as just a line or something
	clarity: number; // 0-1
	excludeFromIntegration?: boolean;

	anchorEntity: string|n;
	shortText = "";
	longText = "";
}
AddSchema("JournalSegment", {
	properties: {
		lucid: {type: "boolean"},
		semiLucid: {type: "boolean"},
		ordered: {type: "boolean"},
		length: {type: "number"},
		wakeTime: {type: ["null", "number"]},
		clarity: {type: "number"},
		excludeFromIntegration: {type: "boolean"},

		anchorEntity: {type: ["null", "string"]},
		shortText: {type: "string"},
		longText: {type: "string"},
	},
});

export type TermsSource = "shortText" | "longText" | "both";
export function GetTermsInDreamSegment(segment: JournalSegment, source: TermsSource, dedup: boolean) {
	const terms = [
		...(source.IsOneOf("shortText", "both") ? GetTermsInDreamSegmentText(segment.shortText) : []),
		...(source.IsOneOf("longText", "both") ? GetTermsInDreamSegmentText(segment.longText) : []),
	];
	return dedup ? terms.Distinct() : terms;
}
export function GetTermsInDreamSegmentText(text: string, dedup = false) {
	let result = text
		.toLowerCase()
		.replace(/\s+/g, " ") // collapse each chain of whitespace (including "\r" and "\n") to just a single space
		.split(" ")
		.map(str=>{
			let charsToKeep = `a-zA-Z0-9\\-_@=`;
			// allow brackets in term-name only if both sides of the bracket are included
			if (str.includes("(") && str.includes(")")) charsToKeep += "()";
			if (str.includes("[") && str.includes("]")) charsToKeep += "[]";
			return str.replace(new RegExp(`[^${charsToKeep}]`, "g"), "");
		})
		.filter(a=>a.length > 0);
	if (dedup) result = result.Distinct();
	return result;
}

export function GetJournalEntryAllText(entry: JournalEntry, lowercase = false) {
	let result = `${entry.title}\n${entry.segments.map(a=>GetSegmentAllText(a)).join("\n")}`;
	if (lowercase) result = result.toLowerCase();
	return result;
}
export function GetSegmentAllText(segment: JournalSegment, lowercase = false) {
	let result = `${segment.shortText}\n${segment.longText}`;
	if (lowercase) result = result.toLowerCase();
	return result;
}