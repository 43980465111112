import {ToJSON} from "js-vextensions";
import {Button, Column, Row, Select, Text, TextArea} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {GetSessionFolderPath} from "../../../../Engine/FBASession.js";
import {DeleteSession} from "../../../../Server/Commands/DeleteSession.js";
import {EngineSessionInfo, GetSettingValueFromEngineSessionInfo} from "../../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {ExportSessionData, ShowFolderInOSExplorer} from "../../../../Utils/Bridge/Bridge_Preload.js";
import {SortKeysInObjectTree} from "../../../../Utils/General/General.js";
import {LogEntry} from "../../../Tools/@Shared/LogEntry.js";
import {GetSessionPeriodStr} from "../../Sessions.js";
import {SessionUI_SharedProps} from "../SessionUI.js";
import {minuteInMS, Observer} from "web-vcore";
import {GetFBAConfig} from "../../../../Store/firebase/fbaConfigs.js";
import {DialogStyle} from "../../../../Utils/UI/GlobalStyles.js";

@Observer
export class SessionUI_GeneralUI extends BaseComponent<{} & SessionUI_SharedProps, {}> {
	render() {
		let {session} = this.props;
		const configFromDB = GetFBAConfig(session.configID);
		return (
			<Column style={{flex: 1}}>
				<Row style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: 18}}>{GetSessionPeriodStr(session)}</Row>
				<Row mt={5} ml={5}>Session ID: {session.id}</Row>
				<Row mt={5} ml={5}>Launch type: {session.launchType}</Row>
				<Row mt={5} ml={5}>Alarm sequence (main): {session.alarms_mainSequence ?? "[none]"}</Row>
				<Row mt={5} ml={5}>Alarm delay: {session.alarmDelay ? session.alarmDelay / minuteInMS : "?"}m</Row>
				<Row mt={5} ml={5}>G: Volume mod: {GetSettingValueFromEngineSessionInfo(session, a=>a.general.globalVolumeMultiplier, 1)}</Row>
				<Row mt={5} ml={5}>G: Alarm-restart interval mod: {GetSettingValueFromEngineSessionInfo(session, a=>a.general.globalAlarmRestartIntervalMultiplier, 1)}</Row>
				<Row mt={5} ml={5}>
					<Text>Config ID: {session.configID} (name: {configFromDB?.name ?? "n/a"})</Text>
					<Button ml={5} text="See config snapshot" onClick={()=>{
						const configCopy = SortKeysInObjectTree(session.config);
						const json = ToJSON(configCopy, undefined, 4);
						ShowMessageBox({
							title: `Snapshot of engine config (${session.configID}), at time of session`,
							message: ()=>(
								//<Column style={DialogStyle({width: 800})}>
								<Column>
									<Row>Config:</Row>
									<TextArea enabled={false} style={{width: 800..KeepAtMost(window.innerWidth - 100), height: window.innerHeight - 200}} value={json}/>
								</Column>
							),
						});
					}}/>
				</Row>
				<Row mt={5}>
					{session.events &&
						<Button ml={5} text="Show events" onClick={()=>{
							ShowMessageBox({
								title: "Events within session",
								message: ()=><Column style={DialogStyle({width: 600})}>
									<TextArea editable={false} style={{width: "100%", height: 500}} value={session.events.map(entry=>JSON.stringify(entry)).join("\n")}/>
								</Column>,
							});
						}}/>}
					{session.logEntries &&
						<Button ml={5} text="Show log" onClick={()=>{
							ShowMessageBox({
								title: "Log entries within session",
								message: ()=><Column style={DialogStyle({width: 600})}>
									<TextArea style={{width: "100%", height: 500}} value={session.logEntries.map(entry=>entry.Cast(LogEntry).toString()).join("\n")}/>
								</Column>,
							});
						}}/>}
					<Button ml={5} text="Full JSON" onClick={()=>{
						ShowMessageBox({
							title: "Full JSON of session",
							message: ()=><Column style={DialogStyle({width: 600})}>
								<TextArea editable={false} style={{width: "100%", height: 500}} value={JSON.stringify(session)}/>
							</Column>,
						});
					}}/>
					<Button ml={5} text="Delete session" onClick={()=>{
						ShowMessageBox({
							title: "Delete session?", cancelButton: true,
							message: `
								Session ID: ${session.id}
								Period: ${GetSessionPeriodStr(session)}
								
								Permanently delete this session?
							`.AsMultiline(0),
							onOK: ()=>{
								new DeleteSession({id: session.id}).Run();
							},
						});
					}}/>
				</Row>
				{session._local &&
				<Row>
					<Button text="Show data folder" onClick={()=>{
						ShowFolderInOSExplorer(GetSessionFolderPath(session._folderName!));
						//ShowFileInOSExplorer(GetSessionSubpath(session._folderName, "SessionInfo.json"));
					}}/>
					{/*<Button ml={5} text="SleepStageTest" onClick={()=>{
						SleepStageTest();
					}}/>*/}
					<Button ml={5} text="Export" onClick={()=>{
						let target = "SleepApp";
						function Change(_) { boxController.UpdateUI(); }
						const boxController = ShowMessageBox({
							title: "Export session data", cancelButton: true,
							message: ()=>{
								return (
									<Column>
										<Row>
											<Text>Target:</Text>
											<Select ml={5} options={["SleepApp", "ZMax"]} value={target} onChange={val=>Change(target = val)}/>
										</Row>
									</Column>
								);
							},
							onOK: ()=>{
								ExportSessionData(session._folderName, target);
							},
						});
					}}/>
				</Row>}
			</Column>
		);
	}
}