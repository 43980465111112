import React from "react";
import {CheckBox, Column, Row, RowLR, Spinner, Text, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {Observer, TextPlus} from "web-vcore";
import {ControlInput} from "../../../@Shared/ControlInput.js";
import {EffectPointerInput} from "../../../@Shared/EffectPointerInput.js";
import {ExtendFBASessionPanelSharedProps, FBASessionPanels_SharedProps} from "../FBAConfigPanel_Local.js";
import {SpinnerForPercent} from "../../../../Utils/ReactComponents/SpinnerForPercent.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.realityCheck, (c, v)=>c.realityCheck = v);

const splitAt = 150;

@Observer
export class RealityCheck_ConfigUI extends BaseComponent<{} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Reality Check" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Signal RC:</Text>
						<ControlInput enabled={enabled} value={config.signalRC_triggerSet} onChange={val=>ChangeConfig(c=>c.signalRC_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Signal RC, effect:</Text>
						<EffectPointerInput enabled={enabled} value={config.signalRC_effect} onChange={val=>ChangeConfig(c=>c.signalRC_effect = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Cue RC:</Text>
						<ControlInput enabled={enabled} value={config.cueRC_triggerSet} onChange={val=>ChangeConfig(c=>c.cueRC_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Cue RC, effect:</Text>
						<EffectPointerInput enabled={enabled} value={config.cueRC_effect} onChange={val=>ChangeConfig(c=>c.cueRC_effect = val)}/>
					</RowLR>

					<Row mt={10} style={{fontWeight: "bold"}}>Reminder</Row>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Delay:</Text>
						<Text>Min:</Text>
						<TimeSpanInput ml={5} enabled={enabled} value={config.reminder_minDelay} onChange={val=>ChangeConfig(c=>c.reminder_minDelay = val)}/>
						<Text ml={5}>Max:</Text>
						<TimeSpanInput ml={5} enabled={enabled} value={config.reminder_maxDelay} onChange={val=>ChangeConfig(c=>c.reminder_maxDelay = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info={`
							Ambient noise is calculated based on the average amplitude of the audio signal over the last 10 seconds.
							Note: Use settings->transcribe->test panel (range:10s, smoothing:10s) to observe ambient noise level.
							Note: If set to 100%, the reminder will always play (avoiding use of the mic for ambient-noise detection).
						`.AsMultiline(0)}>Max ambient noise:</TextPlus>
						<SpinnerForPercent enabled={enabled} value={config.reminder_maxAmbientNoise} onChange={val=>ChangeConfig(c=>c.reminder_maxAmbientNoise = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info={`
							Record an event each time the reminder effect is played.
							(if interval is frequent + consistent, you may want to disable this to save on db/network load)
						`.AsMultiline(0)}>Record play events:</TextPlus>
						<CheckBox enabled={enabled} value={config.reminder_recordPlayEvents} onChange={val=>ChangeConfig(c=>c.reminder_recordPlayEvents = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info={`
							Variables for TTS text:
							* $minutesSinceRC: Minutes since last (app-signaled) reality-check. (integer)
						`.AsMultiline(0)}>Effect:</TextPlus>
						<EffectPointerInput enabled={enabled} value={config.reminder_effect} onChange={val=>ChangeConfig(c=>c.reminder_effect = val)}/>
					</RowLR>
				</>}
			</Column>
		);
	}
}