import {Clone, GetValues_ForSchema, Timer} from "js-vextensions";
import {AddSchema, AssertWarn, StoreAccessor} from "mobx-firelink";
import {minuteInMS, RunInAction, weekInMS} from "web-vcore";
import {UpdateJournalEntry} from "../../../Server/Commands/UpdateJournalEntry.js";
import {GetEntities_WithUserTag} from "../../../Store/firebase/entities.js";
import {FBAConfig_Alarms} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Alarms.js";
import {AlarmSequence} from "../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";
import {GetJournalEntries, Journey_GetJournalEntriesToShow} from "../../../Store/firebase/journalEntries.js";
import {JournalSegment} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {GetSessions} from "../../../Store/firebase/sessions.js";
import {MeID} from "../../../Store/firebase/users.js";
import {store} from "../../../Store/index.js";
import {StatsGrouping, StatsXType, StatsYType} from "../../../Store/main/tools/journey.js";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {JStatsUI_GetTicks} from "../../../UI/Tools/Journey/JourneyStatsUI.js";
import {CreateAggregationMeta, JStatsUI_GetAggregationData} from "../../../UI/Tools/Journey/JourneyStatsUI/AggregationData.js";
import {AudioFileEntry, PlaySound_ByContentUri_ForLiveSession} from "../../../Utils/Bridge/Bridge_Native/MediaPlayer.js";
import {LightPlayer} from "../../../Utils/EffectPlayers/LightPlayer.js";
import {SoundPlayer} from "../../../Utils/EffectPlayers/SoundPlayer.js";
import {NarrateText_ForEngineComp} from "../../../Utils/Services/TTS.js";
import {FBASession, TriggerPackage} from "../../FBASession.js";
import {SessionEvent} from "../SessionEvent.js";
import {AlarmComp} from "./AlarmComps/@AlarmComp.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {ModeSwitcherComp} from "./ModeSwitcherComp.js";
import {GetAudioFilesWithSubpath} from "../../../Store/main/cache.js";
import {SessionCoreData} from "../../../Store/firebase/sessions/@EngineSessionInfo.js";

export enum AlarmsPhase {
	NotStarted,
	InitialDelay,
	Alarm,
	Solving,
	Sleep,
}
AddSchema("AlarmsPhase", {oneOf: GetValues_ForSchema(AlarmsPhase)});

/** Returned alarm-delays are in minutes. */
export const GetAlarmDelayCandidates = StoreAccessor(s=>(config: FBAConfig_Alarms)=>{
	return config.alarmDelay_pool ?? [];
});
export const GetNextAlarmDelayTickets = StoreAccessor(s=>(alarmDelayCandidates: number[], alarmDelayRates: {[key: string]: number}, catchUpRate: number)=>{
	const {view} = store.main.tools.journey.stats;
	const xTicks = JStatsUI_GetTicks(view, false);
	const sessions = GetSessions(MeID());
	const dreams = GetJournalEntries(MeID());
	const meta = CreateAggregationMeta(
		StatsXType.cycleInNight, StatsYType.dreamSegments_sum, [], StatsGrouping.alarmDelay, "",
		view.dateRange_enabled, view.dateRange_min, view.dateRange_max,
		xTicks,
	);
	const aggregationData = JStatsUI_GetAggregationData(sessions, dreams, meta);

	// 1) Calculate the number of first-cycle dream-segments that exist for each alarm-delay.
	// 2) For each alarm-delay, calculate the "target segment-count" for that delay (with "rate" applied), based on the segment-counts of the other delays.
	// 3) Find distance to that target (keep at least 1, since no alarm-delay should have 0% chance), then add that many "tickets" to the "ticket pool" for this delay.
	// 4) We randomly select one of those "tickets" from the pool as our current alarm-delay. (helping to settle on equivalent counts)
	
	const segmentCountsPerAlarmDelay = alarmDelayCandidates.ToMap(alarmDelay=>alarmDelay, alarmDelay=>{
		const alarmDelayAsMinutesStr = alarmDelay.toString();
		return aggregationData.segmentExistenceSamples.GetSampleValues(1, alarmDelayAsMinutesStr).Count();
	});

	const alarmDelayTickets = [] as number[];
	for (let alarmDelay of alarmDelayCandidates) {
		if (alarmDelayRates[alarmDelay] == 0) continue; // if rate is 0, completely ignore this option
		const adjustedSegmentCount_leader = alarmDelayCandidates.filter(a=>a != alarmDelay && a != 0).map(otherDelay=>{
			const ourRateDividedByOtherRate = (alarmDelayRates[alarmDelay] ?? 1) / (alarmDelayRates[otherDelay] ?? 1);
			return segmentCountsPerAlarmDelay.get(otherDelay)! * ourRateDividedByOtherRate;
		}).concat(0).Max();

		const segmentCount = segmentCountsPerAlarmDelay.get(alarmDelay)!;
		const distFromLeader = adjustedSegmentCount_leader - segmentCount;
		const ticketCount = distFromLeader.KeepBetween(1, catchUpRate);
		for (let i = 0; i < ticketCount; i++) {
			alarmDelayTickets.push(alarmDelay);
		}
	}

	return alarmDelayTickets;
});


export class AlarmsComp extends EngineSessionComp<FBAConfig_Alarms> {
	constructor(session: FBASession, config: FBAConfig_Alarms) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	GetTriggerPackages() {
		return [
			new TriggerPackage("Alarms_GoPhaseSleep", this.c.goPhaseSleep_triggerSet, this, {}, async triggerInfo=>{
				this.SetPhase(AlarmsPhase.Sleep);
			}),
			new TriggerPackage("Alarms_GoPhaseAlarm", this.c.goPhaseAlarm_triggerSet, this, {}, async triggerInfo=>{
				const oldPhase = this.phase;
				const wakeEffectsReadyToTriggerThisSleepCycle_oldVal = this.wakeEffectsReadyToTriggerThisSleepCycle;
				if (oldPhase == AlarmsPhase.Sleep) {
					this.Log("Alarm-wait skipped, thus starting the prompt/waker/entry-wait-period again.", LogType.Event_Large);
					this.s.AsLocal!.AddEvent({type: "Journey.ResleepSkip"});
				}
				
				this.SetPhase(AlarmsPhase.Alarm);

				if (oldPhase == AlarmsPhase.Sleep) {
					// avoid a sleep-skip from causing the sleepEnded_waitingForAwakenessTrigger trigger to become able to fire
					if (!wakeEffectsReadyToTriggerThisSleepCycle_oldVal) {
						this.wakeEffectsReadyToTriggerThisSleepCycle = false;
					}
				}
			}),
			new TriggerPackage("Alarms_GoPhaseSolving", this.c.goPhaseSolving_triggerSet, this, {}, async triggerInfo=>{
				//this.SetPhase(AlarmsPhase.Solving);
				this.NotifySolveProgress(5 * 60);
			}),
			new TriggerPackage("Alarms_SignalAwakeness", this.c.signalAwakeness_triggerSet, this, {}, async triggerInfo=>{
				this.SignalAwakeness();
			}),
		];
	}

	async NarrateText(text: string, volumeMultiplier = 1) {
		await NarrateText_ForEngineComp(this, text, this.c.volumeMultiplier * volumeMultiplier, this.c.voiceSoundTag);
	}
	
	// players
	phaseSleep_soundPlayer = new SoundPlayer();
	phaseSleep_lightPlayer = new LightPlayer();
	phaseSolving_soundPlayer = new SoundPlayer();
	phaseSolving_lightPlayer = new LightPlayer();
	//StopPlayers_SleepOrSolving() {}
	/*onPhaseEnter_soundPlayer = new SoundPlayer();
	onPhaseEnter_lightPlayer = new LightPlayer();*/

	// in-session timers
	sleepUntilAlarmTimer: Timer;
	requiredSolveProgressTimer: Timer;

	OnStart(recoveryInfo: SessionCoreData|n) {
		this.phase = AlarmsPhase.InitialDelay;
		/*this.phaseSleep_soundPlayer.sound = GetSounds_WithUserTag(this.c.phaseSleep_soundTag).Random();
		this.phaseSleep_lightPlayer.light = GetLights_WithUserTag(this.c.phaseSleep_lightTag).Random();
		this.phaseSolving_soundPlayer.sound = GetSounds_WithUserTag(this.c.phaseSolving_soundTag).Random();
		this.phaseSolving_lightPlayer.light = GetLights_WithUserTag(this.c.phaseSolving_soundTag).Random();*/

		this.sleepUntilAlarmTimer = new Timer(1_000_000_000, ()=>this.SetPhase(AlarmsPhase.Alarm), 1).SetContext(this.s.timerContext);
		this.SetSleepUntilAlarmTimerDuration(recoveryInfo?.journey_alarmDelay ?? this.GetNextSleepUntilAlarmDuration());

		// the solve-wait duration depends on configuration of whatever caused the solve-wait to happen, so just set it to a super-high initial-value for now
		this.requiredSolveProgressTimer = new Timer(1_000_000_000, ()=>this.NotifySolveFailed(), 1).SetContext(this.s.timerContext);
	}
	OnInitialDelayCompleted() {
		this.SetPhase(AlarmsPhase.Alarm);
	}
	OnStop() {
		this.SetPhase(AlarmsPhase.NotStarted);
	}

	/*StartOrUpdateRequiredSolveProgressTimer(maxTimeForSolveProgressInMS: number) {
		const initialDelayOverride_ifWasActive = CalcInitialDelayOverrideAfterDurationChange_IfTimerActive(this.requiredSolveProgressTimer, maxTimeForSolveProgressInMS);
		this.requiredSolveProgressTimer.intervalInMS = maxTimeForSolveProgressInMS;
		console.log("InitDelayOverride:", initialDelayOverride_ifWasActive);
		this.requiredSolveProgressTimer.Start(initialDelayOverride_ifWasActive);
	}*/
	BumpUpSolveTimeRemainingToAtLeast(minSolveTimeInMS: number) {
		const solveTimeCurrentlyRemaining = this.requiredSolveProgressTimer.Enabled && this.requiredSolveProgressTimer.nextTickTime ? this.requiredSolveProgressTimer.nextTickTime - Date.now() : null;
		if (solveTimeCurrentlyRemaining == null || solveTimeCurrentlyRemaining < minSolveTimeInMS) {
			/*this.requiredSolveProgressTimer.intervalInMS = minSolveTimeInMS;
			this.requiredSolveProgressTimer.Start();*/
			this.requiredSolveProgressTimer.Start(minSolveTimeInMS);
		}
	}
	IsSolvingCurrentlyPossible() {
		return this.PhaseIs(AlarmsPhase.Alarm, AlarmsPhase.Solving);
	}
	solveInProgress = false; // ie. user has started solving, but not yet completed it
	NotifySolveProgress(requiredProgressIntervalInSecs: number, signalsAwakeness = true) {
		if (!this.IsSolvingCurrentlyPossible()) {
			AssertWarn(false, `Solving should not happen unless in phase Alarm or Solving! @phase:${AlarmsPhase[this.phase]}`);
			return;
		}
		if (signalsAwakeness) this.SignalAwakeness();
		if (this.phase != AlarmsPhase.Solving) this.SetPhase(AlarmsPhase.Solving);

		if (!this.solveInProgress) {
			this.solveInProgress = true;
			// needed atm for displaying info like solve-delay in journey ui (event needs rename though)
			this.s.AsLocal!.AddEvent({type: "Journey.CycleStart"});
		}

		this.BumpUpSolveTimeRemainingToAtLeast(requiredProgressIntervalInSecs * 1000);
	}
	NotifySolveCompleted(requiredProgressIntervalInSecs: number, signalsAwakeness = true, eventTextOverride?: string) {
		if (!this.IsSolvingCurrentlyPossible()) {
			AssertWarn(false, "Solving should not happen unless in phase Alarm or Solving!");
			return;
		}
		if (signalsAwakeness) this.SignalAwakeness();
		if (this.phase != AlarmsPhase.Solving) this.SetPhase(AlarmsPhase.Solving);
		if (!this.solveInProgress) this.NotifySolveProgress(requiredProgressIntervalInSecs);
		
		/*const validEntities = this.GetEntitiesMatchingAnyTag(this.c.entityTags_success);
		const successEntityText = validEntities.length ? validEntities.Random().name.split("[")[0].trim() : "Remember lucid";
		this.NarrateText(successEntityText);*/
		// speak the success-message (if set)
		if (this.c.solveSuccess_message?.trim().length != 0) {
			this.NarrateText(this.c.solveSuccess_message);
		}

		// play the success-sound (if set)
		if (this.c.solveSuccess_soundPath.length > 0) {
			const randomSoundMatching = GetAudioFilesWithSubpath(this.c.solveSuccess_soundPath).Random();
			if (randomSoundMatching) {
				PlaySound_ByContentUri_ForLiveSession(randomSoundMatching.contentUri, this.c.solveSuccess_soundVolume, -1, false);
			}
		}
		
		this.s.AsLocal!.AddEvent({type: "Journey.CycleSuccess"}); // needed atm for displaying info like solve-delay in journey ui (event needs rename though)
		this.Log(eventTextOverride ?? "Solve was completed; entering phase sleep.", LogType.Event_Large);
		this.SetPhase(AlarmsPhase.Sleep); // this also sets solveInProgress to false
	}
	NotifySolveFailed() {
		this.s.AsLocal!.AddEvent({type: "Journey.CycleFail"}); // needed atm for displaying info like solve-delay in journey ui (event needs rename though)
		this.Log("Solve failed; entering phase sleep.", LogType.Event_Large);
		this.SetPhase(AlarmsPhase.Alarm); // this also sets solveInProgress to false
	}

	/** The alarm-delay candidates and tickets are in minutes, but the return-value of this func is in milliseconds. */
	GetNextSleepUntilAlarmDuration() {
		const alarmDelayCandidates = GetAlarmDelayCandidates(this.c);
		try {
			const alarmDelayTickets = GetNextAlarmDelayTickets(alarmDelayCandidates, this.c.alarmDelay_poolRates, this.c.alarmDelay_optionCatchUpRate);
			const alarmDelaySelected = alarmDelayTickets.Random();
			this.Log(`Alarm-delay tickets: ${alarmDelayTickets.join(", ")} @selected:${alarmDelaySelected}`, LogType.Event_Large);
			return alarmDelaySelected * minuteInMS;
		} catch (ex) {
			console.warn(`Failed to calculate alarm-duration based on gradual-equalizing system; returning simple-random value. Error:${ex}`);
			return alarmDelayCandidates.Random() * minuteInMS;
		}
	}
	SetSleepUntilAlarmTimerDuration(duration: number) {
		if (this.s.coreData.launchType == "day") duration = weekInMS;
		
		this.sleepUntilAlarmTimer.intervalInMS = duration;
		RunInAction("AlarmsComp.SetSleepUntilAlarmTimerDuration", ()=>this.s.coreData.journey_alarmDelay = duration);
	}
	StartSleepUntilAlarmTimer() {
		if (!this.c.alarmDelay_lockPerSession) {
			this.SetSleepUntilAlarmTimerDuration(this.GetNextSleepUntilAlarmDuration());
		}
		this.sleepUntilAlarmTimer.Start();
	}

	private phase = AlarmsPhase.NotStarted;
	GetPhase() { return this.phase; }
	PhaseIs(...oneOfVals: AlarmsPhase[]) { return oneOfVals.includes(this.phase); }
	SetPhase(newPhase: AlarmsPhase) {
		const oldPhase = this.phase;
		this.BroadcastLeavePhase(oldPhase, newPhase);
		this.Log(`Entering phase: ${AlarmsPhase[newPhase]} (from: ${AlarmsPhase[oldPhase]})`, LogType.Event_Large);
		this.phase = newPhase;
		this.BroadcastStartPhase(oldPhase, newPhase);
	}
	BroadcastLeavePhase(oldPhase: AlarmsPhase, newPhase: AlarmsPhase) {
		this.s.Broadcast({}, a=>a.OnLeavePhase, newPhase, oldPhase);
		if (oldPhase == AlarmsPhase.Sleep) this.s.Broadcast({}, a=>a.OnLeavePhase_Sleep, newPhase);
		else if (oldPhase == AlarmsPhase.Solving) this.s.Broadcast({}, a=>a.OnLeavePhase_Solving, newPhase);
		else if (oldPhase == AlarmsPhase.Alarm) this.s.Broadcast({}, a=>a.OnLeavePhase_Alarm, newPhase);
	}
	BroadcastStartPhase(oldPhase: AlarmsPhase, newPhase: AlarmsPhase) {
		this.s.Broadcast({}, a=>a.OnStartPhase, oldPhase, newPhase);
		if (this.phase == AlarmsPhase.Sleep) this.s.Broadcast({}, a=>a.OnStartPhase_Sleep, oldPhase);
		else if (this.phase == AlarmsPhase.Solving) this.s.Broadcast({}, a=>a.OnStartPhase_Solving, oldPhase);
		else if (this.phase == AlarmsPhase.Alarm) this.s.Broadcast({}, a=>a.OnStartPhase_Alarm, oldPhase);
	}
	/** When a sleep-period ends, this becomes true; when the markAwakenessAfterAlarmWait trigger occurs, an action is done (see GetTriggerPackages() above), then this flag resets to false. */
	wakeEffectsReadyToTriggerThisSleepCycle = false;
	SignalAwakeness() {
		if (!this.wakeEffectsReadyToTriggerThisSleepCycle) return;
		this.wakeEffectsReadyToTriggerThisSleepCycle = false;

		this.s.AsLocal!.AddEvent({type: "General.SleepCycleEnd"});

		const {journalEntryForSession} = Journey_GetJournalEntriesToShow();
		if (journalEntryForSession) {
			const segments_new = Clone(journalEntryForSession.segments) as JournalSegment[];
			// if old last-segment is already a wake-period, create a dream-segment in-between that wake-segment and the new wake-segment we're about to create
			if (segments_new.LastOrX()?.wakeTime != null) {
				const anchorEntities = GetEntities_WithUserTag("anchor"); // DreamPeriodEntryUI.render() keeps this up-to-date
				segments_new.push(new JournalSegment({
					anchorEntity: anchorEntities.Random()?._key,
				}));
			}
			segments_new.push(new JournalSegment({wakeTime: Date.now()}));
			new UpdateJournalEntry({id: journalEntryForSession._key, updates: {segments: segments_new}}).Run();
			this.Log(`Marked awakeness in journal-entry for session.`, LogType.Event_Large);
		}
	}

	OnStartPhase_Sleep(oldPhase: AlarmsPhase) {
		AssertWarn(oldPhase.IsOneOf(AlarmsPhase.Alarm, AlarmsPhase.Solving, AlarmsPhase.Sleep));
		this.s.AsLocal!.AddEvent({type: "Journey.ResleepStart"});

		this.phaseSleep_soundPlayer.FindSound(this.c.phaseSleep_soundTag).Play();
		this.phaseSleep_lightPlayer.FindLight(this.c.phaseSleep_lightTag).Play();
		//ResetLight_Kasa();

		this.StartSleepUntilAlarmTimer();
	}
	OnLeavePhase_Sleep(newPhase: AlarmsPhase) {
		this.sleepUntilAlarmTimer.Stop();
		this.phaseSleep_soundPlayer.Stop();
		this.phaseSleep_lightPlayer.Stop();

		if (newPhase != AlarmsPhase.NotStarted) {
			//this.Log("Alarm-wait completed, thus starting the prompt/waker/entry-wait-period again.", LogType.Event_Large);
			this.s.AsLocal!.AddEvent({type: "Journey.ResleepEnd"});
			
			this.wakeEffectsReadyToTriggerThisSleepCycle = true;
			const modeSwitcher = this.s.Comp(ModeSwitcherComp);
			const targetComp = modeSwitcher.c.onAlarmWaitEnd_setActiveMode;
			//const cycleEntryMatches = modeSwitcher.c.cycleEntries.Any(a=>a.compID == targetComp && !(a.nightOnly && this.s.daytimeMode));
			if (targetComp && modeSwitcher.behaviorEnabled) {
				modeSwitcher.SetActiveComp_ByCompID(targetComp, false);
			}
		}
	}

	// NOTE: These functions assume each sequence has a unique name. (ui layer should probably just enforce this later)
	GetCurrentAlarmSequence(): AlarmSequence|n {
		return this.c.phaseAlarm_sequences.find(a=>a.name == this.s.coreData.alarms_currentSequence);
	}
	SetAlarmCurrentSequence(newSequence: AlarmSequence|n) {
		const oldSequenceName = this.s.coreData.alarms_currentSequence;
		const newSequenceName = newSequence?.name;
		if (newSequenceName == oldSequenceName) return;

		RunInAction("AlarmsComp.OnStartPhase_Alarm", ()=>this.s.coreData.alarms_currentSequence = newSequenceName);

		// disable old sequence's comps
		for (const alarmComp of this.s.components.filter(a=>a instanceof AlarmComp && a.behaviorEnabled) as AlarmComp<any>[]) {
			if (alarmComp.sequence.name == oldSequenceName) {
				alarmComp.OnSequenceDisabled();
			}
		}
	}

	OnStartPhase_Alarm(oldPhase: AlarmsPhase) {
		AssertWarn(oldPhase.IsOneOf(AlarmsPhase.InitialDelay, AlarmsPhase.Solving, AlarmsPhase.Sleep));

		if (this.GetCurrentAlarmSequence() == null || !this.c.phaseAlarm_sequences_lockPerSession) {
			const newSequence = this.c.phaseAlarm_sequences.filter(a=>a.enabled).Random();
			this.SetAlarmCurrentSequence(newSequence);
		}

		//this.s.AsLocal.AddEvent({type: "Journey.PhaseStart_EntryWaitBright"});
		const event = {type: "Journey.LightsBright"} as Partial<SessionEvent>;
		if (this.GetCurrentAlarmSequence()) event.alarmSequence = this.GetCurrentAlarmSequence()!.name;
		this.s.AsLocal!.AddEvent(event);

		// start new sequence's comps (should happen whether target-sequence has changed or not)
		for (const alarmComp of this.s.components.filter(a=>a instanceof AlarmComp && a.behaviorEnabled) as AlarmComp<any>[]) {
			if (alarmComp.sequence == this.GetCurrentAlarmSequence()) {
				alarmComp.OnSequenceStarted();
			}
		}

		// todo: have UI updated to show user what they need to do (now that alarm period is active)
	}
	OnLeavePhase_Alarm(newPhase: AlarmsPhase) {}

	OnStartPhase_Solving(oldPhase: AlarmsPhase) {
		AssertWarn(oldPhase.IsOneOf(AlarmsPhase.Alarm, AlarmsPhase.Solving));

		this.phaseSolving_soundPlayer.FindSound(this.c.phaseSolving_soundTag).Play(this.c.volumeMultiplier);
		this.phaseSolving_lightPlayer.FindLight(this.c.phaseSolving_soundTag).Play(this.c.volumeMultiplier);
	}
	OnLeavePhase_Solving(newPhase: AlarmsPhase) {
		this.requiredSolveProgressTimer.Stop();
		this.phaseSolving_soundPlayer.Stop();
		this.phaseSolving_lightPlayer.Stop();
		this.solveInProgress = false;

		if (newPhase != AlarmsPhase.NotStarted) {
			//this.Log("Micro-snooze duration completed, thus starting the prompt/waker/entry-wait-period again.", LogType.Event_Large);
			this.s.AsLocal!.AddEvent({type: "Journey.SnoozeEnd"});
		}
	}
}