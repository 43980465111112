import chroma from "chroma-js";
import React, {createRef} from "react";
import {Annotation, AnnotationsPlugin, GroupedBarsPlugin, GroupedBarsPluginOptions} from "uplot-vplugins";
import useResizeObserver from "use-resize-observer";
import {AssertUnreachable, Chroma, dayInMS, ES, InfoButton, Observer, RunInAction_Set, TextPlus, uplotDefaults, VDateTime} from "web-vcore";
import {Assert, E, GetEntries, GetPercentFromXToY, Range} from "js-vextensions";
import moment from "moment";
import {UPlot} from "react-uplot";
import {Button, CheckBox, Column, DropDown, DropDownContent, DropDownTrigger, Pre, Row, Select, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import uPlot from "uplot";
import {GetJournalEntries} from "../../../Store/firebase/journalEntries.js";
import {GetSessions} from "../../../Store/firebase/sessions.js";
import {MeID} from "../../../Store/firebase/users.js";
import {store} from "../../../Store/index.js";
import {GraphRenderType_values, JourneyStatsState, StatsGrouping, StatsXType, StatsXType_Label, StatsYType, StatsYType_Label} from "../../../Store/main/tools/journey.js";
import {InAndroid} from "../../../Utils/Bridge/Bridge_Native.js";
import {zIndexes} from "../../../Utils/UI/ZIndexes.js";
import {AggregationData, CreateAggregationMeta, GetValMultiplierForGroupMetricNormalization, GetYValuesForYType, JStatsUI_GetAggregationData} from "./JourneyStatsUI/AggregationData.js";
import {ALL_GROUP_NAME} from "./JourneyStatsUI/MetricCollector.js";
import {StatViewSettingsDropdown} from "./JourneyStatsUI/StatViewDetailsUI.js";
import {GetChartOptions, ShowBarText} from "./JourneyStatsUI/StatsChartOptions.js";
import {PopulateAnnotationsForChart} from "./JourneyStatsUI/AnnotationsPopulator.js";
import {RangeType, StatViewFull} from "../../../Store/firebase/statViews/@StatView.js";
import {JourneyStatsViewsDropdown} from "./JourneyStatsUI/ViewsDropdown.js";

export function JStatsUI_GetTicks(view: StatViewFull, includeSmoothing: boolean) {
	const {xType} = view;

	const extraLeftRangeForTimeBased = includeSmoothing
		? (view.smoothingType == "previous" ? view.smoothing : Math.ceil(view.smoothing / 2)) // todo: make sure Math.ceil is correct
		: 0;

	let ticks: number[];
	if (xType == StatsXType.showAll) {
		ticks = [0];
	} else if (xType == StatsXType.dayOffset) {
		const leftDayOffset = GetLeftEdgeDayOffsetForDayRange(view.dayRange_type, view.dayRange_start, view.dayRange_count);
		ticks = Range(leftDayOffset - extraLeftRangeForTimeBased, 0 - view.days_omitLastX, 1);
	} else if (xType == StatsXType.cycleInGroup) {
		ticks = Range(-view.cyclesToShow - extraLeftRangeForTimeBased, 0, 1);
	} else if (xType == StatsXType.cycleInNight) {
		ticks = Range(1, 10, 1);
	} else {
		AssertUnreachable(xType);
	}
	return ticks;
}

export function GetLeftEdgeDayOffsetForDayRange(rangeType: RangeType, start: number, count: number) {
	if (rangeType == "start") {
		//return moment(start).startOf("day").diff(moment().startOf("day"), "days");
		return GetDayOffset(start);
	}
	if (rangeType == "count") return -count;
	AssertUnreachable(rangeType);
}

export function GetDayOffset(targetTime: number, now = Date.now(), floorResult = true) {
	// consider the "start of the current day" to be: the last 5pm before the current time
	// (reason: the user is very unlikely to be asleep at this time, so it's a good "reset" point)
	// (also: this way, on waking, user can see "latest day" having contents, vs midnight cutoff which makes it empty or underpopulated [depending on segment grouping logic])
	let startOfCurrentFakeDay = new Date(now).setHours(17, 0, 0, 0);
	if (startOfCurrentFakeDay > now) startOfCurrentFakeDay -= dayInMS;

	let dayOffset = (targetTime - startOfCurrentFakeDay) / dayInMS;
	if (floorResult) dayOffset = Math.floor(dayOffset);
	return dayOffset;
}

/*function AddBaseMarker(lineTypes: uPlot.Series[], uplotData: uPlot.AlignedData, ticks: number[], uiState: JourneyStatsState) {
	const {yType} = uiState;

	const baseMarkerSeries = {
		label: "Baseline",
		stroke: "white",
		points: {show: false},
	} as uPlot.Series;
	let baseMarkerData: number[];
	baseMarkerData = ticks.map(x=>0);
	lineTypes.push(baseMarkerSeries);
	uplotData.push(new Float64Array(baseMarkerData));
}*/

@Observer
export class JourneyStatsUI extends BaseComponent<{}, {}> {
	root: HTMLDivElement|n;
	chart = createRef<uPlot>();
	render() {
		const {view} = store.main.tools.journey.stats;
		const {ref: rootRef, width = -1, height = -1} = useResizeObserver();

		// commented; this system is bad, because it relies on this.chart.current, which is null until the *second* complete render (so if nothing retriggers a re-render, render logic depending on it can't work)
		/*const [rootRect, setRootRect] = UseState<VRect|n>(null);
		const [chartBodyRect, setChartBodyRect] = UseState<VRect|n>(null);
		UseEffect(()=>{
			// after each render, find the chart-body subrect, and store it in state (so rating-markers can adjust to it)
			const newRootRect = this.DOM_HTML ? GetPageRect(this.DOM_HTML) : null;
			const newChartBodyRect = this.chart.current?.under ? GetViewportRect(this.chart.current?.under) : null;
			if (newRootRect && newRootRect.width && newChartBodyRect && newChartBodyRect.width) {
				if (!newRootRect.Equals(rootRect)) setRootRect(newRootRect);
				if (!newChartBodyRect.Equals(chartBodyRect)) setChartBodyRect(newChartBodyRect);
			}
		});
		const rectsResolved = rootRect != null && chartBodyRect != null;*/
		
		const series: uPlot.Series[] = [
			{
				label: view.xAxis_labelOverride || StatsXType_Label(view.xType),
				scale: "x",
			} as any,
		];
		const xTicks = JStatsUI_GetTicks(view, false);
		const xTicks_extendedForSmoothing = JStatsUI_GetTicks(view, StatViewFull.WillApplySmoothing(view));
		const uplotData = [xTicks] as uPlot.AlignedData;

		const sessions = GetSessions(MeID());
		const dreams = GetJournalEntries(MeID());
		const meta = CreateAggregationMeta(
			view.xType, view.yType, view.combined_yTypes, view.grouping, view.dreamMatcher_text,
			view.dateRange_enabled, view.dateRange_min, view.dateRange_max,
			xTicks,
		);
		const aggregationData = JStatsUI_GetAggregationData(sessions, dreams, meta);
		if (window["log_aggregationData"]) console.log("Aggregation data:", aggregationData);

		//AddBaseMarker(series, uplotData, xValues, uiState);
		const annotations = [] as Annotation[];

		let groups = [] as string[];
		let groupScales = new Map<string, string>();
		if (view.yType == StatsYType.combined) {
			groups = view.combined_yTypes;
			groupScales = groups.ToMap(a=>a, a=>view.combined_yTypes_scales[a] ? `y_${view.combined_yTypes_scales[a]}` : "y");
		} else {
			if (view.grouping == StatsGrouping.none) {
				groups.push(ALL_GROUP_NAME);
			} else if (view.grouping == StatsGrouping.alarmSequence) {
				for (const alarmSequence of aggregationData.alarmSequences) {
					groups.push(alarmSequence);
				}
			} else if (view.grouping == StatsGrouping.alarmDelay) {
				for (const alarmDelay of aggregationData.alarmDelays) {
					groups.push(alarmDelay.toString());
				}
			} else if (view.grouping == StatsGrouping.volumeMod) {
				for (const globalVolume of aggregationData.globalVolumeMods) {
					groups.push(globalVolume.toString());
				}
			} else if (view.grouping == StatsGrouping.alarmRestartIntervalMod) {
				for (const intervalMod of aggregationData.globalAlarmRestartIntervalMods) {
					groups.push(intervalMod.toString());
				}
			} else {
				AssertUnreachable(view.grouping);
			}
			groups = groups.Exclude(...view.groupExcludes);
			groups = groups.OrderBy(a=>a);
			groupScales = groups.ToMap(a=>a, a=>"y");
		}

		const hueShiftPerGroup = 360 / groups.length;
		const yValuesPerGroup = new Map<string, number[]>();
		for (const [index, group] of groups.entries()) {
			const strokeColor = chroma(hueShiftPerGroup * index, 1, .5, "hsl");
			series.push(E(
				{
					label: StatsYType[group] != null ? StatsYType_Label(group as any) : group,
					scale: groupScales.get(group),
					stroke: strokeColor.css(),
					fill: view.renderType == "bars"
						? strokeColor.css()
						: strokeColor.alpha(.1).css(),
					points: {show: false},
					// stepped-mode
					//paths: paths_stepped,
					paths:
						view.renderType == "bars" ? uPlot.paths.bars!() :
						//uiState.renderType == "bars" ? uPlot.paths.bars!({align: -1}) :
						view.renderType == "spline" ? uPlot.paths.spline!() :
						view.renderType == "linear" ? uPlot.paths.linear!() :
						view.renderType == "stepped" ? uPlot.paths.stepped!({align: -1}) :
						Assert(false, `Invalid render-type: ${view.renderType}`),
				} as uPlot.Series,
				groups.length == 1 && {
					stroke: Chroma("hsla(210,30%,90%,1)").alpha(.4).css(),
					fill: Chroma("hsla(210,30%,90%,1)").alpha(.3).css(),
				},
			));
			const yTypeHere = view.yType == StatsYType.combined ? group as StatsYType : view.yType;
			const groupHere = view.yType == StatsYType.combined ? ALL_GROUP_NAME : group;
			const yValues = GetYValuesForYType(view, xTicks, xTicks_extendedForSmoothing, aggregationData, yTypeHere, groupHere);
			const yValues_nullsAs0 = yValues.map(a=>a ?? 0); // no need to preserve nulls after smoothing has been applied
			uplotData.push(new Float64Array(yValues_nullsAs0)); // array stores the y-values at each x-pos/session-number, for this scheme's line/series
			yValuesPerGroup.set(group, yValues_nullsAs0);
		}

		let chartOptions: uPlot.Options|n;
		if (groups.length) {
			chartOptions = GetChartOptions({width, height, series, annotations, xValues: xTicks, aggregationData});
			PopulateAnnotationsForChart({width, height, chartOptions, annotations, xValues: xTicks, groups, yValuesPerGroup, aggregationData});
		}
		return (
			<Column style={{position: "relative", flex: 1}}>
				<Row style={{flexWrap: "wrap", gap: 5}}>
					<Row center>
						<Pre>X:</Pre>
						<Select ml={5} options={GetEntries(StatsXType, a=>StatsYType_Label(StatsXType[a]))} value={view.xType} onChange={val=>RunInAction_Set(this, ()=>view.xType = val)}/>
					</Row>
					<Row center>
						<Pre>Y:</Pre>
						<Select ml={5} options={GetEntries(StatsYType, a=>StatsYType_Label(StatsYType[a]))} value={view.yType} onChange={val=>RunInAction_Set(this, ()=>view.yType = val)}/>
					</Row>
					{view.yType == "combined" &&
					<CombinedYType_Dropdown/>}
					<Row center>
						<Pre>Group:</Pre>
						<Select ml={5} options={GetEntries(StatsGrouping, a=>StatsYType_Label(StatsGrouping[a]))} value={view.grouping} onChange={val=>RunInAction_Set(this, ()=>view.grouping = val)}/>
					</Row>

					<Row ml="auto">
						<JourneyStatsViewsDropdown/>
						<StatViewSettingsDropdown/>
					</Row>
				</Row>
				<div ref={c=>this.root = c}
					style={ES(
						//{position: "relative"}, //minWidth: 496
						//asNodeUIOverlay && {position: "absolute", left: 0, right: 0, top: 0, bottom: 0, pointerEvents: "none"},
						//{position: "absolute", left: 0, right: 0, top: 30, bottom: 0, pointerEvents: "none"},
						{position: "relative", flex: 1},
					)}>

					{chartOptions != null &&
					<div ref={rootRef as any} className="uplotHolder" style={ES({
						position: "relative", width: "100%",
						//height: "calc(100% - 53px)", // we need to cut off some height, for the legend
						height: "100%",
					})}>
						{width != -1 &&
						<>
							<style>{`
							.u-legend { font-size: 12px; }
							.u-legend .hideLegend { display: none; }
							`}</style>
							<UPlot chartRef={this.chart} options={chartOptions} data={uplotData} ignoreDoubleClick={true}/>
						</>}
					</div>}
					{chartOptions == null &&
					<Text>No data to display.</Text>}
				</div>
			</Column>
		);
	}
}

@Observer
export class CombinedYType_Dropdown extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const {view} = store.main.tools.journey.stats;

		const options = GetEntries(StatsYType).map(a=>{
			return {
				name: a.name,
				label: StatsYType_Label(a.value),
				value: a.value,
			};
		});

		return (
			<DropDown style={{marginLeft: 5}}>
				<DropDownTrigger>
					<Button text="Combined"/>
				</DropDownTrigger>
				<DropDownContent style={{width: 300, zIndex: zIndexes.dropDown, background: "rgba(0,0,0,.9)", borderRadius: 5}}>
					<Row>
						<Text>Y-type (enabled), scale max (0 for auto)</Text>
					</Row>
					{options.map(opt=>{
						return (
							<Row key={opt.value}>
								<CheckBox text={opt.label} value={view.combined_yTypes.includes(opt.value)} onChange={val=>{
									RunInAction_Set(this, ()=>{
										view.combined_yTypes = options.filter(a=>{
											return a.name == opt.name ? val : view.combined_yTypes.includes(a.value);
										}).map(a=>a.value);
									});
								}}/>
								<Spinner ml={5} value={view.combined_yTypes_scales[opt.value] ?? 0} onChange={val=>{
									RunInAction_Set(this, ()=>{
										if (val > 0) view.combined_yTypes_scales[opt.value] = val;
										else delete view.combined_yTypes_scales[opt.value];
									});
								}}/>
							</Row>
						);
					})}
				</DropDownContent>
			</DropDown>
		);
	}
}