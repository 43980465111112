import moment from "moment";
import React from "react";
import {Button, CheckBox, Column, DropDown, DropDownContent, DropDownTrigger, Pre, Row, Select, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {InfoButton, Observer, RunInAction_Set, TextPlus, VDateTime} from "web-vcore";
import {store} from "../../../../Store/index.js";
import {GraphRenderType_values, SmoothingType, StatsGrouping, StatsXType} from "../../../../Store/main/tools/journey.js";
import {zIndexes} from "../../../../Utils/UI/ZIndexes.js";
import {ScrollView} from "react-vscrollview";
import {InAndroid} from "../../../../Utils/Bridge/Bridge_Native.js";
import {GetEntries} from "js-vextensions";
import {RangeType} from "../../../../Store/firebase/statViews/@StatView.js";

export class StatViewSettingsDropdown extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;

		return (
			<DropDown style={{marginLeft: "auto", marginRight: 5, position: "relative"}}>
				<DropDownTrigger>
					<Button mdIcon="cog" size={32}/>
				</DropDownTrigger>
				<DropDownContent style={{
					zIndex: zIndexes.dropDown, position: "fixed", right: 0,
					top: window.innerWidth < 800 ? 60 : 32, // if screen-width <800px, setting-button probably got pushed to second row
					bottom: 0, // bottom:0 = stop at bottom of screen
					background: "rgba(0,0,0,.9)", borderRadius: 5,
				}}>
					<ScrollView style={{height: "100%"}}>
						<StatViewDetailsUI/>
					</ScrollView>
				</DropDownContent>
			</DropDown>
		);
	}
}

@Observer
class StatViewDetailsUI extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const {view} = store.main.tools.journey.stats;
		
		return (
			<>
				<Row>
					<Text>Render type:</Text>
					<Select ml={5} options={GraphRenderType_values} value={view.renderType} onChange={val=>RunInAction_Set(this, ()=>view.renderType = val)}/>
				</Row>
				{view.renderType != "bars" &&
				<Row mt={5} center>
					<Pre>Smoothing:</Pre>
					<Spinner ml={5} min={0} max={100} value={view.smoothing} onChange={val=>RunInAction_Set(this, ()=>view.smoothing = val)}/>
					<CheckBox ml={5} text="Prev" value={view.smoothingType == SmoothingType.previous} onChange={val=>RunInAction_Set(this, ()=>view.smoothingType = val ? SmoothingType.previous : SmoothingType.centered)}/>
				</Row>}
				<Row mt={5} center>
					<TextPlus info={`
						What percentage of samples to keep: 0% = keep only median value, 100% = keep all (average)
						Note: This only applies to a *non-percentage* y-types with the "(typical)" marker. (ie. those using averaging)
					`.AsMultiline(0)}>Keep %:</TextPlus>
					<Spinner ml={5} min={0} max={100} step={5} enforceRange={true} instant style={{width: 50}}
						value={view.middleKeepPercent.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>view.middleKeepPercent = val.FromPercent())}/>
					<Text>%</Text>
				</Row>
				{view.xType == StatsXType.dayOffset && <>
					<Row mt={5} center>
						<Text>Day range:</Text>
						<Select ml={5} displayType="button bar" options={GetEntries(RangeType, "ui")} value={view.dayRange_type} onChange={val=>view.dayRange_type = val}/>
					</Row>
					{view.dayRange_type == RangeType.start &&
					<Row mt={5} ml={5} center>
						<Pre>Count:</Pre>
						<VDateTime dateFormat="YYYY-MM-DD" timeFormat={false} inputProps={{style: {marginLeft: 5, width: 150}}}
							value={view.dayRange_start ? moment(view.dayRange_start) : null}
							onChange={val=>val && RunInAction_Set(this, ()=>view.dayRange_start = val.valueOf())}/>
					</Row>}
					{view.dayRange_type == RangeType.count &&
					<Row mt={5} ml={5} center>
						<Pre>Count:</Pre>
						<Spinner ml={5} min={1} value={view.dayRange_count} onChange={val=>RunInAction_Set(this, ()=>view.dayRange_count = val)}/>
						<Text ml={5}>(start: {moment().add(-(view.dayRange_count - 1), "days").format("MMM D")})</Text>
					</Row>}
					<Row mt={5} ml={5} center>
						<Pre>Omit last X:</Pre>
						<Spinner ml={5} min={0} value={view.days_omitLastX} onChange={val=>RunInAction_Set(this, ()=>view.days_omitLastX = val)}/>
					</Row>
				</>}
				{view.xType == StatsXType.cycleInGroup &&
				<Row mt={5} center>
					<Pre>Cycles:</Pre>
					<Spinner ml={5} min={1} value={view.cyclesToShow} onChange={val=>RunInAction_Set(this, ()=>view.cyclesToShow = val)}/>
				</Row>}
				<Row>Dream matcher:</Row>
				<Column mt={5} ml={10}>
					<Row>
						<TextPlus info={`
							Pad with "/" to use a regex, like this: /text matches are checked on dream (short|long) text/
						`.AsMultiline(0)}>Text:</TextPlus>
						<TextInput ml={5} style={{flex: 1}} value={view.dreamMatcher_text} onChange={val=>RunInAction_Set(this, ()=>view.dreamMatcher_text = val)}/>
					</Row>
				</Column>
				<CheckBox mt={5} text="Show lucid count" value={view.showLucidCount} onChange={val=>RunInAction_Set(this, ()=>view.showLucidCount = val)}/>
				<CheckBox mt={5} text="Show lucid rate" value={view.showLucidRate} onChange={val=>RunInAction_Set(this, ()=>view.showLucidRate = val)}/>
				<Row mt={5}>
					<CheckBox text="Normalize group metrics:" value={view.normalizeGroupMetrics} onChange={val=>RunInAction_Set(this, ()=>view.normalizeGroupMetrics = val)}/>
					<InfoButton ml={5} text={`
						Problem: When comparing alarm-delay groups, metrics for shorter-delays look worse due to hits being spread among more cycles.
						Solution: This normalizes certain metrics, making their values comparable for evaluating the "total of X" to expect per night.
						Application: These metrics are affected (if grouping is "Alarm delay"): Tags (typical), Words (typical), and lucidity-rate annotation
						Example: If standard alarm-delay is 80mins, a per-cycle lucidity-rate of 5% in a 40mins alarm-delay group gets normalized to 10% for display.
					`.AsMultiline(0)}/>
				</Row>
				{view.normalizeGroupMetrics &&
				<Row mt={5} ml={10}>
					<Text>Standard alarm delay:</Text>
					<Spinner ml={5} value={view.normalizeGroupMetrics_alarmDelay} onChange={val=>RunInAction_Set(this, ()=>view.normalizeGroupMetrics_alarmDelay = val)}/>
				</Row>}
				<Row mt={5}>
					<CheckBox text="Date range:" value={view.dateRange_enabled} onChange={val=>RunInAction_Set(this, ()=>view.dateRange_enabled = val)}/>
					<InfoButton ml={5} text={`
						Note that this also filters the pool used for applying the engine Journey component's "Option catch-up rate" system.
					`.AsMultiline(0)}/>
				</Row>
				{view.dateRange_enabled && <>
					<Row mt={5}>
						<Text mr={5} style={{flex: 1}}>From:</Text>
						<VDateTime dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" inputProps={{style: {width: 150}}}
							value={view.dateRange_min ? moment(view.dateRange_min) : null}
							onChange={val=>RunInAction_Set(this, ()=>view.dateRange_min = val?.valueOf() ?? null)}/>
					</Row>
					<Row mt={5}>
						<Text mr={5} style={{flex: 1}}>To:</Text>
						<VDateTime dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" inputProps={{style: {width: 150}}}
							value={view.dateRange_max ? moment(view.dateRange_max) : null}
							onChange={val=>RunInAction_Set(this, ()=>view.dateRange_max = val?.valueOf() ?? null)}/>
					</Row>
				</>}
				<Row>
					<CheckBox text="Date markers:" value={view.showDateMarkers} onChange={val=>RunInAction_Set(this, ()=>view.showDateMarkers = val)}/>
					<Button ml={5} mdIcon="plus" onClick={()=>RunInAction_Set(this, ()=>view.dateMarkers.push(new Date().setHours(0, 0, 0, 0)))}/>
				</Row>
				{view.showDateMarkers && view.dateMarkers.map((date, index)=>{
					return (
						<Row key={index} mt={5}>
							<VDateTime dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" inputProps={{style: {width: 150}}}
								value={date ? moment(date) : null}
								onChange={val=>RunInAction_Set(this, ()=>view.dateMarkers[index] = val?.valueOf() ?? null)}/>
							<Button ml={5} mdIcon="delete" onClick={()=>RunInAction_Set(this, ()=>view.dateMarkers.RemoveAt(index))}/>
						</Row>
					);
				})}
				
				<Row>
					<Text>Group excludes:</Text>
					<Button ml={5} mdIcon="plus" onClick={()=>RunInAction_Set(this, ()=>view.groupExcludes.push(""))}/>
				</Row>
				{view.groupExcludes.map((group, index)=>{
					return (
						<Row key={index} mt={5}>
							<TextInput style={{flex: 1, width: 100}} value={group} onChange={val=>RunInAction_Set(this, ()=>view.groupExcludes[index] = val)}/>
							<Button ml={5} mdIcon="delete" onClick={()=>RunInAction_Set(this, ()=>view.groupExcludes.RemoveAt(index))}/>
						</Row>
					);
				})}

				<Row mt={5}>Label overrides:</Row>
				<Row mt={5}>
					<Text>Y-axis:</Text>
					<TextInput ml={5} style={{flex: 1}} value={view.yAxis_labelOverride} onChange={val=>RunInAction_Set(this, ()=>view.yAxis_labelOverride = val)}/>
				</Row>
				<Row mt={5}>
					<Text>X-axis:</Text>
					<TextInput ml={5} style={{flex: 1}} value={view.xAxis_labelOverride} onChange={val=>RunInAction_Set(this, ()=>view.xAxis_labelOverride = val)}/>
				</Row>

				<Row mt={5}>
					<CheckBox text="Y-axis max override:" value={view.yAxis_maxOverride_enabled} onChange={val=>RunInAction_Set(this, ()=>view.yAxis_maxOverride_enabled = val)}/>
					{view.yAxis_maxOverride_enabled &&
					<Spinner ml={5} value={view.yAxis_maxOverride} onChange={val=>RunInAction_Set(this, ()=>view.yAxis_maxOverride = val)}/>}
				</Row>
			</>
		);
	}
}