import uPlot from "uplot";
import {Annotation, AnnotationsPlugin, GroupedBarsPlugin, GroupedBarsPluginOptions} from "uplot-vplugins";
import {uplotDefaults} from "web-vcore";
import {store} from "../../../../Store/index.js";
import {StatsGrouping, StatsXType, StatsXType_Label, StatsYType, StatsYType_Label} from "../../../../Store/main/tools/journey.js";
import {InAndroid} from "../../../../Utils/Bridge/Bridge_Native.js";
import {AggregationData} from "../JourneyStatsUI/AggregationData.js";
import chroma from "chroma-js";
import {GetGroupedBarsPluginConfig} from "./GroupedBarDisplayCalc.js";

export function ShowBarText() {
	const {view} = store.main.tools.journey.stats;
	// if on android, don't show bar-text for day-offset view (otherwise it gets too cluttered)
	if (InAndroid(0) && view.xType == StatsXType.dayOffset) return false;
	return true;
}

export function GetChartOptions(args: {
	width: number, height: number, series: uPlot.Series[], annotations: Annotation[],
	xValues: number[], aggregationData: AggregationData,
}) {
	const {width, height, series, annotations, xValues, aggregationData} = args;
	const {view} = store.main.tools.journey.stats;
	const legendHeight = 33; // from dev-tools

	const yScales = series.filter(a=>a.scale != "x").map(a=>a.scale as string).Distinct(); //.OrderBy(a=>a);
	const yAxis_maxOverride = view.yAxis_maxOverride_enabled ? view.yAxis_maxOverride : null; // access now, for mobx-registering

	const chartOptions: uPlot.Options = {
		id: `stats_chart`,
		class: "stats-chart",
		width,
		height: height - legendHeight,
		plugins: [
			AnnotationsPlugin({
				annotations,
			}),
		] as uPlot.Plugin[],
		cursor: {
			drag: {x: false, setScale: false},
		},
		axes: [
			{
				...uplotDefaults.axis_props_base,
				scale: "x",
				label: view.xAxis_labelOverride || StatsXType_Label(view.xType),
				//time: false,

				// label section
				labelFont: "bold 12px Arial",
				labelSize: 12,

				// ticks section
				font: "9px Arial",
				size: 25,
				gap: 3,
				space: 20,
				incrs: [1, 5, 10, 50, 100, 250, 500, 1000, 5000, 10000],
			},
			...yScales.map((scale, i)=>{
				const scaleSeries = series.filter(a=>a.scale == scale);
				const base: uPlot.Axis = {
					...uplotDefaults.axis_props_base,
					scale,
					side: i == 0 ? 3 : 1,
					
					// label section
					labelFont: "bold 12px Arial",
					labelSize: 12,

					// ticks section
					font: "9px Arial",
					size: 40,
					gap: 3,
					space: 20,
					incrs: [.01, .1, 1, 5, 10, 50, 100, 250, 500, 1000, 5000, 10000],
				};

				if (scale?.startsWith("y_")) {
					const scaleMax = parseFloat(scale.slice(2));
					return {
						...base,
						label: `${scaleSeries.map(a=>a.label).join(", ")}`, // (max: ${scaleMax})`,
						// if max-val is 3-digits or less, we can max this scale's display-area take even less width
						size: scaleMax <= 999 ? 30 : 40,
						stroke: series.length > 2 ? chroma.average(scaleSeries.map(a=>a.stroke + "")).css() : "white",
					};
				}

				let label = view.yType == StatsYType.combined
					? `${scaleSeries.map(a=>a.label).join(", ")}` // (max: auto)`
					: StatsYType_Label(view.yType);
				if (view.yAxis_labelOverride) label = view.yAxis_labelOverride;
				return {
					...base,
					label,
					stroke: series.length > 2 ? chroma.average(scaleSeries.map(a=>a.stroke + "")).css() : "white",
				};
			}),
		],
		scales: {
			x: {
				time: false,
			},
			...yScales.ToMapObj(scaleName=>scaleName as string, (scaleName, i)=>{
				return {
					range: (u, dataMin, dataMax)=>{
						if (scaleName?.startsWith("y_")) {
							const scaleMax = parseFloat(scaleName.slice(2));
							return [0, scaleMax];
						}

						// ensure that the y-axis displays up to at least 1 (useful for percentage-based data-displays)
						//if (dataMax < 1) dataMax = 1;

						let heightMultiplier = 1;
						if (view.showLucidCount || view.showLucidRate) {
							heightMultiplier = 1.1; // make some space for lucid count/rate annotations above highest bar
						}
						return [0, (yAxis_maxOverride ?? dataMax) * heightMultiplier];
					},
				};
			}),
		},
		series,
	};

	// use the grouped-bars-plugin for all bar-based drawing (it's better to standardize on one path, since there are behavior differences, eg. regular bars plugin cuts off a portion of the bars of the first and last x-values)
	if (view.renderType == "bars") {
		const config = GetGroupedBarsPluginConfig();
		chartOptions.plugins!.push(GroupedBarsPlugin(config));
		chartOptions.axes![1].side = config.ori == "horizontal" ? 3 : 0;
		for (const [scaleName, scale] of Object.entries(chartOptions.scales!)) {
			if (scaleName != "x") scale.ori = config.ori == "horizontal" ? 1 : 0;
		}

		const maxXTicksShown = InAndroid(0) ? 15 : 30;

		// prevent there from being more x-values/ticks labels shown that fits in ui (the grouped-bars plugin adds a label for every x-value by default)
		if (xValues.length > maxXTicksShown) {
			chartOptions.plugins!.push({
				opts: (u, uplotOpts)=>{
					uPlot.assign(uplotOpts.axes![0], {
						// simple approach; works, but not ideal (tends to leave off a fittable x-value label near left edge)
						/*splits: null,
						values: null,
						incrs: [1, 2, 5, 10],*/
						// custom approach; more code, but gives a predictable result
						splits: (u, axisIdx)=>{
							const result = u._data[0];
							if (result.length > maxXTicksShown) {
								// omit enough x-values to stay within the X-tick limit (while being one of the nice split-intervals below)
								const keep1PerXTicks = [1, 2, 5, 10].find(a=>result.length / a <= maxXTicksShown)! ?? 10;
								return result.filter((a, i)=>i % keep1PerXTicks == 0);
							}
							return result;
						},
						values: u=>{
							const result = u.data[0];
							if (result.length > maxXTicksShown) {
								// omit enough x-values to stay within the X-tick limit (while being one of the nice split-intervals below)
								const keep1PerXTicks = [1, 2, 5, 10].find(a=>result.length / a <= maxXTicksShown)! ?? 10;
								return result.filter((a, i)=>i % keep1PerXTicks == 0);
							}
							return result;
						},
					});
				},
				hooks: {},
			});
		}
	}

	// reuse/hide x-values series in legend
	chartOptions.hooks ??= {};
	chartOptions.hooks.init = [
		u=>{
			const legendBoxes = [...u.root.querySelectorAll(".u-legend .u-series")] as HTMLElement[];
			// if using grouping, hijack the first series (which is the x-values series) to explain the series used for the data-grouping instead
			if (view.grouping != StatsGrouping.none) {
				const colorBoxEl = legendBoxes[0].childNodes[0].childNodes[0] as HTMLDivElement;
				const labelEl = legendBoxes[0].childNodes[0].childNodes[1] as HTMLDivElement;
				const valueEl = legendBoxes[0].childNodes[1] as HTMLTableCellElement;

				colorBoxEl.style.display = "none"; // hide color-box (not helpful)
				// change label-part to show what the group's series represent
				labelEl.innerText =
					view.grouping == StatsGrouping.alarmSequence ? "Alarm sequence" :
					view.grouping == StatsGrouping.alarmDelay ? "Alarm delay" :
					view.grouping == StatsGrouping.volumeMod ? "Volume mod" :
					view.grouping == StatsGrouping.alarmRestartIntervalMod ? "Alarm-restart interval mod" :
					"[unknown grouping]";
				valueEl.style.display = "none"; // and hide the value-displaying part
			}
			// if not using grouping, hide the x-values series (since atm it doesn't display the actual x-values anyway; caused by grouped-bars plugin, but need to investigate root cause)
			else {
				legendBoxes[0].style.display = "none";
			}
		},
	];

	return chartOptions;
}