import {AddSchema, GetSchemaJSON, NewSchema} from "mobx-firelink";
import {GetValues, GetValues_ForSchema} from "js-vextensions";
import {GraphRenderType, SmoothingType, StatsGrouping, StatsXType, StatsYType} from "../../main/tools/journey.js";
import moment from "moment";
import {makeObservable} from "mobx";
import {O} from "web-vcore";

export enum RangeType {
	start = "start",
	count = "count",
}
AddSchema("RangeType", {enum: GetValues(RangeType)});

export const StatView_metaKeys = ["_key", "creator", "createdAt", "name"] as const;
export const StatView_NormalKeys = ()=>Object.keys(GetSchemaJSON("StatView").properties!).Exclude(...StatView_metaKeys);

export class StatViewFull {
	static WillApplySmoothing(view: StatViewFull) { return (view.smoothing ?? 0) > 0 && view.renderType != "bars"; }
	
	constructor(data?: Partial<StatViewFull>) {
		makeObservable(this);
		Object.assign(this, data);
	}

	_key?: string;
	creator?: string;
	createdAt?: number;
	name?: string;

	@O xType = StatsXType.dayOffset;
	@O yType = StatsYType.termsInLongText;
	@O combined_yTypes: StatsYType[] = [StatsYType.quizPrompts_sum, StatsYType.quizFirstTryHitPercent];
	@O combined_yTypes_scales: {[key: string]: number} = {[StatsYType.quizPrompts_sum]: 500, [StatsYType.quizFirstTryHitPercent]: 100};
	@O grouping = StatsGrouping.none;

	// dropdown
	// ==========

	@O renderType: GraphRenderType = "bars";
	@O middleKeepPercent = 1; // 0-1
	@O smoothing = 0;
	@O smoothingType = SmoothingType.previous;
	@O dayRange_type = RangeType.count;
	@O dayRange_start = moment("2020-01-01").valueOf();
	@O dayRange_count = 30;
	@O days_omitLastX = 0;
	@O cyclesToShow = 30;

	@O dreamMatcher_text = "";
	@O showLucidCount = true;
	@O showLucidRate = true;
	@O normalizeGroupMetrics = true;
	@O normalizeGroupMetrics_alarmDelay = 80; // in minutes
	@O dateRange_enabled = false;
	@O dateRange_min: number|n = moment("2020-01-01").valueOf();
	@O dateRange_max: number|n = moment("2020-01-01").valueOf();
	@O showDateMarkers = true;
	@O dateMarkers = [] as Array<number|n>;
	@O groupExcludes = [] as string[];
	@O yAxis_labelOverride = "";
	@O xAxis_labelOverride = "";
	@O yAxis_maxOverride_enabled = false;
	@O yAxis_maxOverride = 0;
}

export type StatView = Partial<StatViewFull>;
AddSchema("StatView", {
	properties: {
		creator: {type: "string"},
		createdAt: {type: "number"},
		name: {type: "string"},
		xType: {$ref: "StatsXType"},
		yType: {$ref: "StatsYType"},
		combined_yTypes: {items: {$ref: "StatsYType"}},
		combined_yTypes_scales: {patternProperties: {"\\w+": {type: "number"}}},
		grouping: {$ref: "StatsGrouping"},

		renderType: {$ref: "GraphRenderType"},
		middleKeepPercent: {type: "number"},
		smoothing: {type: "number"},
		smoothingType: {$ref: "SmoothingType"},
		dayRange_type: {$ref: "RangeType"},
		dayRange_start: {type: "number"},
		dayRange_count: {type: "number"},
		days_omitLastX: {type: "number"},
		cyclesToShow: {type: "number"},
		
		dreamMatcher_text: {type: "string"},
		showLucidCount: {type: "boolean"},
		showLucidRate: {type: "boolean"},
		normalizeGroupMetrics: {type: "boolean"},
		normalizeGroupMetrics_alarmDelay: {type: "number"},
		dateRange_enabled: {type: "boolean"},
		dateRange_min: {type: ["number", "null"]},
		dateRange_max: {type: ["number", "null"]},
		showDateMarkers: {type: "boolean"},
		dateMarkers: {items: {type: ["number", "null"]}},
		groupExcludes: {items: {type: "string"}},
		yAxis_labelOverride: {type: "string"},
		xAxis_labelOverride: {type: "string"},
		yAxis_maxOverride_enabled: {type: "boolean"},
		yAxis_maxOverride: {type: "number"},
	},
});