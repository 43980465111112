import React from "react";
import {BaseComponent} from "react-vextensions";
import {Row, Spinner, SpinnerProps, Text} from "react-vcomponents";
import {Assert} from "js-vextensions";

export type SpinnerForPercentProps = {
	step_spin?: number|"any"|n, step_display?: number|n,
	value: number|n,
} & Omit<SpinnerProps, "value">;

export class SpinnerForPercent extends BaseComponent<SpinnerForPercentProps, {}> {
	static defaultProps = {step: 1};
	
	render() {
		let {step_spin, step_display, value, onChange, ...rest} = this.props;
		step_spin ??= "any";
		step_display ??= .01;
		return (
			<>
				<Spinner min={0} max={100} {...rest}
					step={step_spin}
					value={value?.ToPercent(step_display)}
					onChange={(val, e)=>{
						if (onChange) {
							return onChange(val.FromPercent(), e);
						}
					}}/>
				<Text>%</Text>
			</>
		);
	}
}