import {GetJournalEntry} from "../../Store/firebase/journalEntries";
import {StoreAccessor} from "mobx-firelink";
import {O} from "web-vcore";
import {makeObservable} from "mobx";

export enum JournalDisplayMode {
	list = "list",
	grid = "grid",
}

export enum ClarityForm {
	Percent = 10,
	Stars = 20,
}

export enum SummarizeTab {
	simple = "simple",
	llmTemplate = "llmTemplate",
	llmGenerate = "llmGenerate",
}

export enum CleanupTab {
	llmTemplate = "llmTemplate",
	llmGenerate = "llmGenerate",
}

export class JournalState {
	constructor() { makeObservable(this); }
	//@O selectedUser: string;
	@O displayMode = JournalDisplayMode.grid;

	// list
	@O textPreviewMultiline = false;
	@O filter = new JournalFilterState();

	// grid
	@O entriesPerGridCell = 3;

	@O selectedJournalEntry: string|n;
	@O extraControls = false;
	@O clarityForm = ClarityForm.Stars;
	@O summarize_tab = SummarizeTab.llmGenerate;
	@O summarize_llmTemplate = llmTemplateDefault_summarize;
	@O cleanup_tab = CleanupTab.llmGenerate;
	@O cleanup_llmTemplate = llmTemplateDefault_cleanup;
}
export const llmTemplateDefault_summarize = `
Please create a VERY compact summary of the following dream.

Rules:
* Be very compact. (grammar rules can be skipped)
* Separate events using semicolons (;).
* The entire summary should be lowercase, and in chronological order. (even if original was not)
* Don't talk about your making a summary, just return the summary itself.
* For dreamer's actions, say "i" or "me" instead of "dreamer"; and actually, omit "i"/"me" unless it's needed for disambiguation.

Dream:
\`\`\`
$dream
\`\`\`
`.trim();
export const llmTemplateDefault_cleanup = `
Please clean up the text of the following dream (which used imperfect speech-to-text transcription).

Objectives:
* Fix punctuation.
* Ensure complete sentences. (remove or replace punctuation marks to do so)
* Add paragraph breaks.
* Suggest fixes for possibly mis-transcribed words.

Rules:
* Preserve all words from the original text. (this includes timestamps!)
* If you think a word was mis-transcribed, KEEP THE ORIGINAL, and simply ADD your best guess in brackets: like dis [this?]
* Avoid saying "Here is the cleaned up text" or similar; just return the cleaned-up text on its own.

Dream:
\`\`\`
$dream
\`\`\`
`.trim();

export class JournalFilterState {
	constructor() { makeObservable(this); }
	@O showOnlyLucids = false;
	// text_include is an entry-level AND segment-level filter
	@O text_include = "";
	@O filterWithinJournalEntry = false;

	// segment-level filters
	@O showOnlyIntegratable = false;
	@O dreamEvents_enabled = false;
	@O dreamEvents_min = -1;
	@O dreamEvents_max = -1;
	@O words_enabled = false;
	@O words_min = -1;
	@O words_max = -1;
}

export const GetSelectedJournalEntryID = StoreAccessor(s=>()=>{
	return s.main.journal.selectedJournalEntry;
});
export const GetSelectedJournalEntry = StoreAccessor(s=>()=>{
	const selectedID = GetSelectedJournalEntryID();
	return GetJournalEntry(selectedID);
});