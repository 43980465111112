import {StoreAccessor, GetDocs, WhereOp, GetDoc, QueryOp} from "mobx-firelink";
import {StatViewFull, StatView} from "./statViews/@StatView";

export const GetStatViews = StoreAccessor(s=>(userID: string): StatView[]=>{
	const result = GetDocs({
		queryOps: [
			userID != null && new WhereOp("creator", "==", userID),
		].filter(a=>a) as QueryOp[],
	}, a=>a.statViews);
	return result.map(CleanUpStatViewFromDB);
});
export const GetStatView = StoreAccessor(s=>(id: string): StatView|n=>{
	const result = GetDoc({}, a=>a.statViews.get(id));
	return CleanUpStatViewFromDB(result);
});

function CleanUpStatViewFromDB<T extends StatView|n>(view: T): T {
	if (view == null) return view;
	const fieldValsThatMayHaveKeyProp = [view.combined_yTypes_scales];
	for (const fieldVal of fieldValsThatMayHaveKeyProp) {
		if (fieldVal == null) continue;
		if ("_key" in fieldVal) {
			delete fieldVal["_key"];
		}
	}
	return view;
}